import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import RecordingWidget, { IStep } from '../Components/RecordingWidget'
import Header from '../Components/UI/Header/Header'
import LockIcon from '@mui/icons-material/Lock'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../Hooks/StoreHooks'
import { setGoal } from '../Store/brain/brainReducer'
import { setQuestions } from '../Store/form/formReducer'
import useMixpanel from '../Hooks/useMixpanel'

const questionsBase = [
  {
    tag: "Understand the problem I'm solving",
    goal: 'Act as an enterpreneur. Explain in a numeric list: who is the ideal customer profile ? what is the problem we are solving ? what are the sub problems of this problem ?',
    questions: [
      'What is the pain point or challenge that your target audience is facing?',
      'Tell me the story of one of your potential clients and how they face the problem ?',
      'What are the current solutions that are available in the market and how are they falling short?',
      'What are the consequences of not solving this problem?',
      'Is this problem common and widespread or is it unique to a niche market?',
      'What are the underlying causes of the problem?',
      'What are the needs, desires, and motivations of your target audience in relation to this problem?',
      'What are the potential benefits of solving this problem?',
      // 'What resources, skills, and expertise do you need to solve this problem effectively?',
      // 'What are the potential risks, challenges, and limitations involved in solving this problem?',
    ],
  },
  {
    tag: 'Develop my elevator pitch',
    goal: 'Act as an enterpreneur and write a transcript elevator pitch for the product. emphasize the strength of the product. It should be objective and not sound like a sales pitch.',
    questions: [
      "What is your product's name ?",
      'What problem does my product or service solve?',
      'Who is my target customer?',
      'What is the unique value proposition of my product or service?',
      "How is my product or service different from what's already available in the market?",
      'What is the current market size and potential growth for my product or service?',
      'How will I acquire and retain customers?',
      'What is the business model and revenue strategy for my product or service?',
      'What is the current stage of development and what are the next steps?',
      'What is the funding or investment strategy for my business?',
    ],
    locked: true,
  },
  {
    tag: 'Develop a traction strategy',
    goal: 'What are the best two traction channels for my startup ? write a basic strategy for each one',
    questions: [
      'Who is my target audience?',
      'Where does my target audience spend their time online and offline?',
      'What are the key pain points of my target audience and how does my product/service address them?',
      'What are the most effective ways to communicate the benefits of my product/service to my target audience?',
      'What are my competitors doing to reach their target audience and what can I learn from them?',
      'How much time and money can I invest in each traction channel, and what is the potential return on investment (ROI) for each channel?',
    ],
    locked: true,
  },
  {
    tag: 'Understand my positioning',
    goal: '',
    questions: [],
    locked: true,
  },
  {
    tag: 'Develop a pricing model',
    goal: '',
    questions: [],
    locked: true,
  },
  {
    tag: 'Develop a customer onboarding strategy',
    goal: '',
    questions: [],
    locked: true,
  },
]

const Init = () => {
  return (
    <Box
      sx={{
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box sx={{ maxWidth: '1200px', marginTop: '20px' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', maxWidth: '800px' }}>
          What would you like to brainstorm ?
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '50px',
          display: 'flex',
          justifycontent: 'center',
          maxWidth: '1200px',
        }}
      >
        <Box maxWidth="1200px" width="100%">
          <Grid
            sx={{ maxWidth: '900px', marginBottom: '20px' }}
            container
            spacing={1}
            justifyContent="flex-start"
            alignContent="flex-start"
          >
            {questionsBase.map((qb, i) => {
              return <CardTemplate what={qb.tag} questionData={qb} key={i} locked={qb.locked} />
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

{
  /* <CardTemplate what="My elevator pitch" />
<CardTemplate what="The problem I am solving" locked />
<CardTemplate what="The solution I offer" locked />
<CardTemplate what="My ideal customer profile" locked />
<CardTemplate what="Build SaaS onboarding process" locked />
<CardTemplate what="Apply a product led growth strategy" locked />
<CardTemplate what="The best traction channels for my business" locked />
<CardTemplate what="Build a marketing funnel" locked /> */
}

const performDispatch = (dispatch: any, formData: any) => {
  dispatch(setGoal(formData.goal))
  dispatch(setQuestions(formData.questions))
}
const CardTemplate: React.FC<any> = ({ what, locked = false, questionData }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const mixpanel = useMixpanel()

  const onClick = () => {
    if (!locked) {
      performDispatch(dispatch, questionData)
      // navigate('/instructions')
      navigate('/answer')
    }
    mixpanel.track('template_clicked', { page: 'Init', questionData, locked: questionData.locked })
  }
  return (
    <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card
        onClick={onClick}
        sx={{
          width: 270,
          height: 270,
          '&:hover': {
            background: locked ? '' : 'rgba(167, 212, 248, 0.3)',
          },
          cursor: 'pointer',
          display: 'flex  ',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: locked && '0.4',
        }}
        elevation={1}
      >
        {' '}
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '85%',
          }}
        >
          <Box sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <Typography variant="h6" component="div">
              {what}
            </Typography>
          </Box>
          {locked ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Typography color="warning" sx={{ fontStyle: 'italic' }} variant="subtitle2">
                Early access only
              </Typography>
              <LockIcon />
            </Box>
          ) : (
            <Box sx={{}}></Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}
export default Init
