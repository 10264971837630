import React from 'react'
import { Box } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { getObjectsArrayKeys } from '../../Utils/getObjectsArrayKeys'
import { v4 } from 'uuid'
import { Filter } from './Filter'

export const Filters: React.FC<any> = ({ data, onChange = () => null, initialFilters }) => {
  const [filters, setFilters] = React.useState<any>(initialFilters || [])
  const keys = getObjectsArrayKeys(data).filter((o: any) => o.type === 'string')
  React.useEffect(() => {
    if (filters.length > 0) {
      const newFilters = filters.filter(
        (f: any) =>
          !!f?.selectedProperty &&
          !!f?.selectedValues &&
          f.selectedValues.length > 0 &&
          !!f?.filterer
      )
      onChange(newFilters)
    } else {
      onChange([])
    }
  }, [filters, data])
  const removeFilter = (filterId: string) => {
    setFilters((prev: any) => prev.filter((f: any) => f.id !== filterId))
  }

  const updateFilter = (filter: any) => {
    const withoutCurrent = filters.filter((f: any) => f.id !== filter.id)

    setFilters([...withoutCurrent, filter])
  }

  return (
    <Box
      sx={{
        marginTop: '20px',
        marginBottom: '20px',
        border: '1px solid #e2e2e2',
        padding: '10px',
        borderRadius: '5px',
      }}
    >
      <Box>
        <Typography variant="body1" sx={{ fontWeight: '520', color: '#8f8f8f', fontSize: '13px' }}>
          FILTERS
        </Typography>
      </Box>
      {filters.map((filter: any) => {
        return (
          <Filter
            key={filter.id}
            filter={filter}
            options={keys}
            data={data}
            removeFilter={removeFilter}
            updateFilter={updateFilter}
          />
        )
      })}
      <Button
        sx={{ marginTop: '10px' }}
        variant="outlined"
        onClick={() => setFilters((prev: any) => [...prev, { id: v4() }])}
      >
        add
      </Button>
    </Box>
  )
}
