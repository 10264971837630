import React from 'react'
import { Box } from '@mui/system'
import { Autocomplete, Chip, TextField } from '@mui/material'
import { arrayUnique } from '../../Utils/arrayUnique'
import ClearIcon from '@mui/icons-material/Clear'
import { Filterer } from './Filterer'

export const Filter: React.FC<any> = ({ filter, options, data, removeFilter, updateFilter }) => {
  const [selectedProperty, setSelectedProperty] = React.useState<any>(
    filter?.selectedProperty || undefined
  )
  const [filterer, setFilterer] = React.useState<string>(filter?.filterer || '')
  const [selectedValues, setSelectedValues] = React.useState<any>(filter?.selectedValues || [])

  React.useEffect(() => {
    if (selectedValues.length > 0) {
      if (filterer !== '') {
        if (selectedProperty)
          updateFilter({ selectedValues, selectedProperty, id: filter.id, filterer })
      }
    } else {
      updateFilter({ id: filter.id })
    }
  }, [selectedValues, selectedProperty, filter.id, filterer])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Box sx={{ padding: '10px' }}>
        <Autocomplete
          size="small"
          disablePortal
          id="propertySelector"
          options={options.map((a: any) => a.key)}
          sx={{ minWidth: '200px' }}
          onChange={(event: any, newValue: string | null) => {
            setSelectedProperty(options.find((o: any) => o.key === newValue))
            setFilterer('')
            setSelectedValues([])
          }}
          value={selectedProperty?.key}
          renderInput={(params) => <TextField {...params} />}
          fullWidth
        />
      </Box>
      <Box sx={{ padding: '10px' }}>
        <Filterer
          type={selectedProperty?.type || 'unknown'}
          filterer={filterer}
          onChange={setFilterer}
        />
      </Box>
      <Box sx={{ padding: '10px' }}>
        {selectedProperty && filterer !== '' ? (
          <Autocomplete
            size="small"
            disablePortal
            id="valueSelector"
            options={arrayUnique(
              data
                .filter((a: any) => !!a[selectedProperty?.key])
                .map((a: any) => String(a[selectedProperty?.key]))
            )}
            sx={{ minWidth: '200px' }}
            multiple
            value={selectedValues}
            onChange={(event: any, newValue: any) => {
              setSelectedValues(newValue)
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                return (
                  <Chip
                    label={`${option.substring(0, 15)}${option.length > 15 ? '...' : ''}`}
                    {...getTagProps({ index })}
                  />
                )
              })
            }
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          ''
        )}
      </Box>
      <Box>
        <ClearIcon
          sx={{ color: 'gray', fontSize: '14px', cursor: 'pointer' }}
          onClick={() => removeFilter(filter.id)}
        />
      </Box>
    </Box>
  )
}
