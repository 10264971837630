import mixpanel from 'mixpanel-browser'

mixpanel.init('d6f2a345eeb9e0caaec57d66f95b071f', { debug: false })

const useMixpanel = () => {
  const track = (name: string, props: any = {}) =>
    mixpanel.track(name, { ...props, subscription: 'free', version: 'v3' })

  return {
    track,
  }
}

export default useMixpanel
