import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

type TUserState = {
  isLoggedIn: boolean
}

const initialState: TUserState = {
  isLoggedIn: false,
}

export const recordingWidgetSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true
    },
    logout: (state) => {
      state.isLoggedIn = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { login, logout } = recordingWidgetSlice.actions
export const selectRecordingWidget = (state: RootState) => state.recordingWidget
export default recordingWidgetSlice.reducer
