import React from 'react'
import { UserMediaContext } from '../Contexts/UserMediaContext'
import { useUserMedia } from '../Hooks/mediaStream/useMediaStream'
import AuthService from './Services/AuthService'
import AutoPilot from './Services/AutoPilot'
import Reacher from './Services/Reacher'
import UpdateTimerOnRecording from './Services/UpdateTimerOnRecording'
import NotificationContainer from './Notification/Notification/NotificationContainer'

const Services: React.FC<any> = ({ children }) => {
  const userMedia = useUserMedia()
  return (
    <>
      <UserMediaContext.Provider
        value={{
          stream: userMedia.stream,
          invoke: userMedia.invoke,
          stop: userMedia.stop,
          chunks: userMedia.chunks,
        }}
      >
        <UpdateTimerOnRecording />
        <AuthService />
        <NotificationContainer />
        {children}
      </UserMediaContext.Provider>
    </>
  )
}

export default Services
