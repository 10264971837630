import { Box, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'
import { drawerWidth } from '../InternalHeader/Constants'

const SessionSidebar = () => {
  const { id } = useParams()
  const [requestSent, setRequestSent] = React.useState<boolean>(false)
  return (
    <Box
      sx={{
        borderRight: '1px solid #e6e6e6',
        background: '#ffffff',
        width: `${drawerWidth}px`,
        padding: '20px',
      }}
    >
      <Box>
        <Typography variant="body2" sx={{ fontWeight: 'bold', padding: '10px' }}>
          Language preferences
        </Typography>
      </Box>
      {!requestSent ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              padding: '2px',
              cursor: 'pointer',
              '&:hover': {
                background: 'rgb(173, 216, 230, 0.3)',
              },
            }}
          >
            <Box
              sx={{ padding: '10px' }}
              onClick={() => {
                axios.post(
                  `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/session/${id}/lang`,
                  {
                    lang: 'he-IL',
                  }
                )

                setRequestSent(true)
              }}
            >
              <Typography variant="body2">Convert to hebrew</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              padding: '2px',
              cursor: 'pointer',
              '&:hover': {
                background: 'rgb(173, 216, 230, 0.3)',
              },
            }}
          >
            <Box
              sx={{ padding: '10px' }}
              onClick={() => {
                axios.post(
                  `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/session/${id}/lang`,
                  {
                    lang: 'en-US',
                  }
                )

                setRequestSent(true)
              }}
            >
              <Typography variant="body2">Convert to english</Typography>
            </Box>
          </Box>
        </>
      ) : (
        <Box>
          <Typography variant="body2">Request sent, processing it..</Typography>
        </Box>
      )}
    </Box>
  )
}

export default SessionSidebar
