import { Box, Typography } from '@mui/material'
import axios from 'axios'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../Hooks/StoreHooks'
import { selectBrain } from '../../Store/brain/brainReducer'
import { selectForm } from '../../Store/form/formReducer'
import animatedLogo from '../../Assets/brainanime2.gif'
import GetResultsToEmail from './GetResultsByEmail'
import { v4 } from 'uuid'

const Digest = () => {
  const brain = useAppSelector(selectBrain)
  const form = useAppSelector(selectForm)
  const navigate = useNavigate()

  useEffect(() => {
    if (form.questions?.length > 0) {
      const sessionId = v4()
      const promises = form.questions.map((q) => {
        return axios
          .post('https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/brain/memory/add', {
            fileId: brain.fileId,
            brainId: brain.id,
            text: q.text,
            start: q.startTime,
            end: q.endTime,
            formId: q.formId,
            recordingStart: form.questions[0]?.startTime,
            sessionId,
            reachId: form.reachId,
          })
          .then(() => {
            // memory added
          })
      })
      Promise.all(promises).then(() => {
        navigate('/answer/generate')
      })
    }
  }, [form.questions])

  // useEffect(() => {
  //   axios
  //     .post('https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/brain/analyse', {
  //       fileId: brain.fileId,
  //       brainId: brain.id,
  //     })
  //     .then(() => {
  //       navigate('/answer/generate')
  //     })
  // }, [brain])

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={animatedLogo} />
      <Typography variant="caption" sx={{ marginTop: '10px' }}>
        Please wait
      </Typography>
      <Typography variant="h6" sx={{}}>
        Digesting information
      </Typography>
      <GetResultsToEmail />
    </Box>
  )
}

export default Digest
