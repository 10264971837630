import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import { selectCurrentForm } from '../../Store/brain/brainReducer'
import { fetchFormsByBrain } from '../../Store/brain/brainReducer'
import axios from 'axios'
import { useNotification } from '../../Hooks/useNotification'
import useMixpanel from '../../Hooks/useMixpanel'
const SettingsTab = () => {
  const currentForm = useAppSelector(selectCurrentForm)
  const dispatch = useAppDispatch()
  const notify = useNotification()
  const mixpanel = useMixpanel()
  const [formData, setFormData] = React.useState({
    ...currentForm,
    settings: {
      segmentDescription: currentForm?.settings?.segmentDescription || '',
      productDescription: currentForm?.settings?.productDescription || '',
      meetingLength: currentForm?.settings?.meetingLength || '',
      meetingWindow: currentForm?.settings?.meetingWindow || '',
      meetingPurpose: currentForm?.settings?.meetingPurpose || '',
      challenges: currentForm?.settings?.challenges || '',
      valueProps: currentForm?.settings?.valueProps || '',
    },
  })

  React.useEffect(() => {
    setFormData({
      ...currentForm,
      settings: {
        segmentDescription: currentForm?.settings?.segmentDescription || '',
        productDescription: currentForm?.settings?.productDescription || '',
        meetingLength: currentForm?.settings?.meetingLength || '',
        meetingWindow: currentForm?.settings?.meetingWindow || '',
        meetingPurpose: currentForm?.settings?.meetingPurpose || '',
        challenges: currentForm?.settings?.challenges || '',
        valueProps: currentForm?.settings?.valueProps || '',
      },
    })
  }, [currentForm, setFormData])
  const formSettings = formData?.settings || {}

  const updateFormData = () => {
    notify.add({ type: 'info', message: 'Updating campaign settings...' })
    mixpanel.track('clicked_save_campaign_settings', { ...formSettings })
    axios
      .post(
        `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/${currentForm.id}/settings/update`,
        {
          settings: { ...formSettings },
        }
      )
      .then(() => {
        notify.add({ type: 'info', message: 'Campaign settings updated..' })
        mixpanel.track('camapign_settings_save_success', { ...formSettings })
        dispatch(fetchFormsByBrain())
      })
      .catch(() => {
        mixpanel.track('camapign_settings_save_error', { ...formSettings })
        notify.add({ type: 'error', message: 'Error updating campaign settings' })
        dispatch(fetchFormsByBrain())
      })
  }

  const handleChange = (elementName: string) => (event: any) => {
    setFormData({
      ...formData,
      settings: { ...formData.settings, [elementName]: event.target.value },
    })
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <Box>
          <Typography variant="h6">Segment description</Typography>
        </Box>
        <Box>
          <Typography variant="caption" sx={{ color: 'gray', fontStyle: 'italic' }}>
            Please tell me a little bit about this campaign segment.
          </Typography>
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            label="User segment description"
            variant="outlined"
            sx={{ width: '100%' }}
            value={formSettings.segmentDescription}
            onChange={handleChange('segmentDescription')}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <Box>
          <Typography variant="h6">Product Description</Typography>
        </Box>
        <Box>
          <Typography variant="caption" sx={{ color: 'gray', fontStyle: 'italic' }}>
            Tell me a bit about the product you are trying to sell.
          </Typography>
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            label="Product description"
            variant="outlined"
            multiline
            rows={4}
            sx={{ width: '100%' }}
            value={formSettings.productDescription}
            onChange={handleChange('productDescription')}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <Box>
          <Typography variant="h6">Meeting Settings</Typography>
        </Box>
        <Box>
          <Typography variant="caption" sx={{ color: 'gray', fontStyle: 'italic' }}>
            Please set the meeting length and the time windows.
          </Typography>
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            label="Meeting length, ex: 30 minutes"
            variant="outlined"
            sx={{ width: '100%', marginTop: '5px' }}
            value={formSettings.meetingLength}
            onChange={handleChange('meetingLength')}
          />
        </Box>
        <Box sx={{ marginTop: '5px' }}>
          <TextField
            id="outlined-basic"
            label="Time window, ex: next Tuedsay 10:00-15:00 or Wednesday 15:00-19:00 IST time"
            variant="outlined"
            sx={{ width: '100%', marginTop: '5px' }}
            value={formSettings.meetingWindow}
            onChange={handleChange('meetingWindow')}
          />
        </Box>
        <Box sx={{ marginTop: '5px' }}>
          <TextField
            id="outlined-basic"
            label="What is the meeting purpose ? ex: schedule a meeting for a demo with client"
            variant="outlined"
            sx={{ width: '100%', marginTop: '5px' }}
            value={formSettings.meetingPurpose}
            onChange={handleChange('meetingPurpose')}
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <Box>
          <Typography variant="h6">Challenges</Typography>
        </Box>
        <Box>
          <Typography variant="caption" sx={{ color: 'gray', fontStyle: 'italic' }}>
            Please list the challenges this segment faces. you can elaborate with exampmle discovery
            questions.
          </Typography>
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            label="The prospect's challenges"
            variant="outlined"
            multiline
            rows={4}
            sx={{ width: '100%' }}
            value={formSettings.challenges}
            onChange={handleChange('challenges')}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <Box>
          <Typography variant="h6">Value Proposition</Typography>
        </Box>
        <Box>
          <Typography variant="caption" sx={{ color: 'gray', fontStyle: 'italic' }}>
            Please list the value propositions your product offers to this segment.
          </Typography>
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            label="Value propositions"
            variant="outlined"
            multiline
            rows={4}
            sx={{ width: '100%' }}
            value={formSettings.valueProps}
            onChange={handleChange('valueProps')}
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: '10px' }}>
        <Button onClick={() => updateFormData()} variant="contained" fullWidth>
          Save Settings
        </Button>
      </Box>
    </>
  )
}

export default SettingsTab
