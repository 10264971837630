import React from 'react'
import { useNavigate } from 'react-router-dom'
import { UserMediaContext } from '../../Contexts/UserMediaContext'
import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import {
  getQuestionsCount,
  moveNext,
  selectCurrentQuestionIndex,
} from '../../Store/form/formReducer'
import {
  IRecorderState,
  selectRecorder,
  stopRecording,
  updateRecorderTime,
} from '../../Store/recorder/recorder'

const UpdateTimerOnRecording = () => {
  const recordingState = useAppSelector(selectRecorder)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const questionsCount = useAppSelector(getQuestionsCount)
  const currentQuestionIndex = useAppSelector(selectCurrentQuestionIndex)
  const umCtx = React.useContext(UserMediaContext)

  React.useEffect(() => {
    if (recordingState.status === IRecorderState.Recording)
      if (120 - parseInt(recordingState.time.toString()) <= 0) {
        dispatch(updateRecorderTime(0))
        if (currentQuestionIndex + 1 === questionsCount) {
          umCtx.stop()
          navigate('/answer/newbrain')
          dispatch(stopRecording())
        } else {
          dispatch(moveNext())
        }
      }
  }, [recordingState, currentQuestionIndex, questionsCount, umCtx, dispatch])

  React.useEffect(() => {
    let timer: any
    if (recordingState.status === IRecorderState.Recording) {
      timer = setInterval(() => {
        dispatch(updateRecorderTime(recordingState.time + 0.1))
      }, 100)
    }
    return () => {
      return clearTimeout(timer)
    }
  }, [recordingState, dispatch])
  return <></>
}

export default UpdateTimerOnRecording
