import { useCallback } from 'react'
import { sendExtMessage } from '../../Utils/extension/sendExtMessage'

const useExtension = () => {
  const send = useCallback((a: any) => sendExtMessage(a), [])

  return {
    send,
  }
}

export default useExtension
