import { Box, Typography } from '@mui/material'
import axios from 'axios'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { useAppDispatch } from '../../Hooks/StoreHooks'
import { setBrainId } from '../../Store/brain/brainReducer'
import animatedLogo from '../../Assets/brainanime2.gif'
import useMixpanel from '../../Hooks/useMixpanel'

const NewBrain = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    const brainId = v4()
    axios
      .post('https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/brain/create')
      .then((res) => {
        dispatch(setBrainId(res.data.id))
        navigate('/answer/upload')
      })
  }, [navigate, dispatch])

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={animatedLogo} />
      <Typography variant="caption" sx={{ marginTop: '10px' }}>
        Please wait
      </Typography>
      <Typography variant="h6" sx={{}}>
        We are giving birth to a new brain
      </Typography>
    </Box>
  )
}

export default NewBrain
