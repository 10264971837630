import { Box, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'
import animatedLogo from '../../Assets/brainanime2.gif'
import { useAppSelector } from '../../Hooks/StoreHooks'
import { selectBrain } from '../../Store/brain/brainReducer'

const loaded = false
const ContactsCmpt = () => {
  const [contacts, setContacts] = React.useState([])
  const brain = useAppSelector(selectBrain)

  React.useEffect(() => {
    if (brain.id)
      axios
        .post(
          `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/brain/${brain.id}/contacts`
        )
        .then((res) => {
          setContacts(res.data.contacts)
        })
  }, [setContacts, brain])

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Typography variant="caption">{contacts.length} contacts</Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          background: '#f2f2f2',
          border: '1px solid #e2e2e2',
          display: 'flex',
          padding: '5px',
        }}
      >
        <Box sx={{ width: '25%' }}></Box>
        <Box sx={{ width: '25%' }}>Name</Box>
        <Box sx={{ width: '25%' }}>Source</Box>
        <Box sx={{ width: '25%' }}>Occupation</Box>
      </Box>
      {contacts.map((c, i) => (
        <Contact contact={c} key={i} />
      ))}
    </Box>
  )
}

const Contact: React.FC<any> = ({ contact }) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          background: '#fff',
          border: '1px solid #e2e2e2',
          display: 'flex',
          padding: '5px',
        }}
      >
        <Box sx={{ width: '25%' }}></Box>
        <Box sx={{ width: '25%' }}>{contact?.name}</Box>
        <Box sx={{ width: '25%' }}>
          {contact?.source === 'linkedin_contact' ? 'Linkedin' : 'Unknown'}
        </Box>
        <Box sx={{ width: '25%' }}>{contact?.occupation}</Box>
      </Box>
    </>
  )
}

export default ContactsCmpt
