import { Box } from '@mui/system'
import React from 'react'
import animatedLogo from '../../Assets/brainanime.gif'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { useAppDispatch } from '../../Hooks/StoreHooks'
import { setBrainId, setGoal } from '../../Store/brain/brainReducer'
import { setQuestions } from '../../Store/form/formReducer'
import axios from 'axios'

const MentorCmp = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoadig, setIsLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    axios
      .post(`https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/${id}/questions`)
      .then((res) => {
        if (res?.data?.brainId) {
          dispatch(setGoal("Summarize the answers' main ideas in one clear paragraph."))
          dispatch(setBrainId(res.data.brainId))
          dispatch(setQuestions(res.data.questions))
        }
      })
      .then(() => {
        // navigate('/answer')
        setIsLoading(false)
      })
  }, [dispatch, id])

  if (isLoadig)
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={animatedLogo} style={{ width: '120px' }} />
        <Typography variant="caption" sx={{ marginTop: '10px' }}>
          Powered by The Big Brain
        </Typography>
        <Typography variant="h6" sx={{}}>
          Preparing survey
        </Typography>
      </Box>
    )

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box>
        <img src={animatedLogo} style={{ width: '120px' }} />
      </Box>
      <Box sx={{ padding: '40px', width: { md: '70%', xs: '90%' } }}>
        <Typography variant="h5">
          You are invited to participate in a survey. When you click the button below, you'll be
          asked to give microphone permission. Then you will be redirected to a new page to help us
          answer a few questions verbally.
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={() => navigate('/answer')}
        sx={{ boxShadow: 'none', padding: '10px', fontSize: '20px', fontWeight: 'regular' }}
      >
        Click here to start
      </Button>
    </Box>
  )
}

export default MentorCmp
