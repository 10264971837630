import { createContext } from 'react'
import { IStream, pendingStreamBuilder } from '../Hooks/mediaStream/useMediaStream'

export const UserMediaContext = createContext<{
  stream: IStream
  invoke: any
  stop: any
  chunks: any[]
}>({
  stream: pendingStreamBuilder(),
  invoke: () => null,
  stop: () => null,
  chunks: [],
})
