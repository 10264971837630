import { Box } from '@mui/material'
import React from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import Header from '../Components/InternalHeader/Header'
import SessionCmp from '../Components/Session/SessionCmp'
import SessionSidebar from '../Components/Session/SessionSidebar'

const Session = () => {
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
        }}
      >
        <SessionSidebar />
        <Box sx={{ display: 'flex', width: '100%', flex: 1, padding: '20px' }}>
          <SessionCmp />
        </Box>
      </Box>
    </Box>
  )
}

export default Session
