import React, { useEffect } from 'react'
import { UserMediaContext } from '../../Contexts/UserMediaContext'
import useStorageUpload from '../../Hooks/firebase/useStorageUpload'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../Hooks/StoreHooks'
import { setBrainFileId } from '../../Store/brain/brainReducer'
import { Box } from '@mui/system'
import animatedLogo from '../../Assets/brainanime2.gif'
import { Typography } from '@mui/material'
import useMixpanel from '../../Hooks/useMixpanel'
const UploadResult = () => {
  const umCtx = React.useContext(UserMediaContext)
  const uploader = useStorageUpload()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [percentage, setPercentage] = React.useState<number>(0)

  useEffect(() => {
    if (umCtx.chunks.length > 0) {
      const blob = new Blob(umCtx.chunks, { type: 'audio/webm;codecs=opus' })
      const fileId = v4()
      const filePath = 'audio/' + fileId
      uploader
        .uploadFromBlob(filePath, blob, (p) => {
          if (!isNaN(p)) setPercentage(Math.floor(p))
        })
        .then(() => {
          dispatch(setBrainFileId(filePath))
          navigate('/answer/digest')
        })
    }
  }, [setPercentage, umCtx, navigate, dispatch])

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={animatedLogo} />
      <Typography variant="caption" sx={{ marginTop: '10px' }}>
        Please wait
      </Typography>
      <Typography variant="h6" sx={{}}>
        Uploading session ({percentage.toString()}%)
      </Typography>
    </Box>
  )
}

export default UploadResult
