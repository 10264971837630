import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  buildUserError,
  buildUserLoadingState,
  buildUserSignedIn,
  buildUserSignedOut,
  isErrorUser,
  isLoadingUser,
  isUserSignedIn,
  IUser,
  IUserUnionStates,
} from './UserType'
import type { RootState } from '../store'
import axios from 'axios'
import { fetchBrainData } from '../brain/brainReducer'

// Define the initial state using that type
type IStateType = { payload: IUserUnionStates }
const initialState: IStateType = { payload: buildUserLoadingState() }

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserLoading: (state: IStateType) => {
      state.payload = buildUserLoadingState()
    },
    setUserSignedOut: (state: IStateType) => {
      state.payload = buildUserSignedOut()
    },
    setUserSignedIn: (state: IStateType, action: PayloadAction<IUser>) => {
      state.payload = buildUserSignedIn(action.payload)
    },
    setUserError: (state: IStateType) => {
      state.payload = buildUserError()
    },
  },
})

export const { setUserLoading, setUserSignedOut, setUserSignedIn, setUserError } = userSlice.actions

export const saveUserDetails = createAsyncThunk('user/signUserIn', async (_, thunkAPI) => {
  try {
    const response = await axios.post(
      'https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/user/me'
    )
    thunkAPI.dispatch(setUserSignedIn(response.data))
    thunkAPI.dispatch(fetchBrainData(response.data.selectedBrain))
    return
  } catch {
    thunkAPI.dispatch(setUserError())
  }
})

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user.payload
export const isLoading = (state: RootState) => isLoadingUser(state.user.payload)
export const isLoggedIn = (state: RootState) => isUserSignedIn(state.user.payload)
export const userHasError = (state: RootState) => isErrorUser(state.user.payload)

export default userSlice.reducer
