import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

type TQuestion = {
  text: string
  id: string
  formId: string
  startTime?: number
  endTime?: number
}
type TFormState = {
  questions: TQuestion[]
  currentQuestionIndex: number
  reachId?: string
}

const basicQuestions: TQuestion[] = []

const initialState: TFormState = {
  questions: basicQuestions,
  currentQuestionIndex: 0,
}

export const recorderSlice = createSlice({
  name: 'recorder',
  initialState: initialState as TFormState,
  reducers: {
    moveToIndex: (state, action: PayloadAction<number>) => {
      state.currentQuestionIndex = action.payload
    },
    moveNext: (state) => {
      state.questions[state.currentQuestionIndex].endTime = Date.now()
      state.questions[state.currentQuestionIndex + 1].startTime = Date.now()
      state.currentQuestionIndex = state.currentQuestionIndex + 1
    },
    setQuestions: (state, action: PayloadAction<any[]>) => {
      state.questions = action.payload
    },
    setReachId: (state, action: PayloadAction<string | undefined>) => {
      state.reachId = action.payload
    },
    registerFormStartedRecording: (state) => {
      state.questions[state.currentQuestionIndex].startTime = Date.now()
    },
  },
})

// Action creators are generated for each case reducer function
export const { moveToIndex, moveNext, registerFormStartedRecording, setQuestions, setReachId } =
  recorderSlice.actions

export const selectForm = (state: RootState) => state.form
export const selectCurrentQuestion = (state: RootState) =>
  state.form.questions[state.form.currentQuestionIndex]
export const selectCurrentQuestionIndex = (state: RootState) => state.form.currentQuestionIndex

export const isFirstQuestion = (state: RootState) => state.form.currentQuestionIndex === 0
export const isLastQuestion = (state: RootState) =>
  state.form.currentQuestionIndex === state.form.questions.length - 1
export const getQuestionsCount = (state: RootState) => state.form.questions.length

export default recorderSlice.reducer
