import { useCallback, useEffect, useState } from 'react'
import { selectCurrentQuestionIndex } from '../../Store/form/formReducer'
import useStorageUpload from '../firebase/useStorageUpload'
import { useAppDispatch, useAppSelector } from '../StoreHooks'

export enum IStreamStates {
  Pending = 0,
  Loading = 1,
  Ready = 2,
  Error = 3,
}

export type IReadyStream = {
  state: IStreamStates.Ready
  stream: MediaStream
}

type ILoadingStream = {
  state: IStreamStates.Loading
}

export type IPendingStream = {
  state: IStreamStates.Pending
}

export const pendingStreamBuilder = (): IStream => ({
  state: IStreamStates.Pending,
})

export type IErrorStream = {
  state: IStreamStates.Error
  error: Error
}

export type IStream = IReadyStream | IPendingStream | ILoadingStream | IErrorStream

/**
 *
 * Gets the user's media stream and saves it
 *
 */

export const useUserMedia = () => {
  const [stream, setStream] = useState<IStream>({
    state: IStreamStates.Pending,
  })
  const [outputStream, setOutputStream] = useState<IStream>({
    state: IStreamStates.Pending,
  })

  const [chunks, setChunks] = useState<any>([])

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const askForCameraPermissions = useCallback(() => {
    setStream({
      state: IStreamStates.Loading,
    })

    navigator.mediaDevices
      .getUserMedia({
        video: false,
        audio: true,
      })
      .then((mediaStream) => {
        setStream({
          state: IStreamStates.Ready,
          stream: mediaStream,
        })
        const mr = new MediaRecorder(mediaStream)
        setMediaRecorder(mr)
      })
      .catch((err) => {
        setStream({
          state: IStreamStates.Error,
          error: err,
        })
      })
  }, [])

  useEffect(() => {
    if (mediaRecorder !== null) {
      mediaRecorder.start()
      mediaRecorder.ondataavailable = (e: BlobEvent) => {
        setChunks((prev: any) => [...prev, e.data])
      }
    }
  }, [mediaRecorder])

  // closes the camera when recording is over

  const stop = () => {
    // stop media recorder when finitto
    mediaRecorder?.stop()
    if (stream && isReadyStream(stream)) {
      ;(stream as IReadyStream).stream.getTracks().forEach(function (track: MediaStreamTrack) {
        track.stop()
      })
    }
  }

  useEffect(() => {
    if (isReadyStream(stream)) {
      if ((stream as IReadyStream).stream.active) setOutputStream(stream)
    } else {
      setOutputStream(stream)
    }
  }, [stream])

  return { stream: outputStream, invoke: askForCameraPermissions, stop, chunks }
}

export const isLoadingStream = (stream: IStream) => stream.state === IStreamStates.Loading
export const isReadyStream = (stream: IStream) => stream.state === IStreamStates.Ready
export const isPendingStream = (stream: IStream) => stream.state === IStreamStates.Pending
export const isErrorStream = (stream: IStream) => stream.state === IStreamStates.Error

async function parseBlobToText(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsText(blob)
  })
}
