import axios from 'axios'
import React, { useEffect } from 'react'
import { useAppSelector } from '../../Hooks/StoreHooks'
import { selectBrain } from '../../Store/brain/brainReducer'
import animatedLogo from '../../Assets/brainanime2.gif'
import { Box, Typography } from '@mui/material'
import EarlyAccessWidget from '../EarlyAccess/EarlyAccessWidget'
import useMixpanel from '../../Hooks/useMixpanel'

const Generate = () => {
  const brain = useAppSelector(selectBrain)
  const [onePager, setOnePager] = React.useState<string | null>()
  const mixpanel = useMixpanel()

  useEffect(() => {
    if (brain.goal)
      axios
        .post('https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/brain/ask', {
          brainId: brain.id,
          question: `Q: ${brain.goal}\n A:`,
        })
        .then((res) => {
          setOnePager(res.data.post)
        })
  }, [brain, setOnePager])

  React.useEffect(() => {
    if (onePager) {
      mixpanel.track('generation_done', { ...brain, post: onePager })
    }
  }, [onePager, mixpanel, brain])

  if (!onePager)
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={animatedLogo} />
        <Typography variant="caption" sx={{ marginTop: '10px' }}>
          Please wait
        </Typography>
        <Typography variant="h6" sx={{}}>
          Generating overview
        </Typography>
      </Box>
    )

  return (
    <Box
      sx={{
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box sx={{ marginTop: '20px', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            maxWidth: '600px',
            border: '1px solid #ADD8E6',
            borderRadius: '10px',
            padding: '20px',
            background: 'rgba(173, 216, 230, 0.1)',
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            This is the feedback we've got!
          </Typography>
          <Typography sx={{ marginTop: '20px' }} variant="body2">
            {onePager.split('\n').map((line, idx) => (
              <Box key={idx}>{line}</Box>
            ))}
          </Typography>
          <EarlyAccessWidget />
        </Box>
      </Box>
    </Box>
  )
}

export default Generate
