import { extension_key } from '../../Config/extension_key'

type TSendExtMessageProps = {
  action: string
  payload?: any
}

export const sendExtMessage = ({ action, payload }: TSendExtMessageProps): Promise<any> =>
  new Promise((res, rej) => {
    console.log(extension_key)
    chrome.runtime.sendMessage(
      extension_key,
      {
        action,
        payload,
      },
      (result: any) => {
        if (chrome.runtime.lastError) rej(chrome.runtime.lastError)
        res(result)
      }
    )
  })
