import React from 'react'
import { ThemeProvider } from '@mui/material/styles'

import { QueryClient, QueryClientProvider } from 'react-query'

import { Provider } from 'react-redux'
import { store } from '../Store/store'
import { TNone } from '../Types/globals'
import Router from './Router'
import theme from '../Config/theme'

const queryClient = new QueryClient()

const AppContainer: React.FC<TNone> = () => {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Router />
          </Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </React.Fragment>
  )
}

export default AppContainer
