import { GoogleAuthProvider, signInWithPopup, signOut, getAuth } from 'firebase/auth'
import { useAppDispatch } from '../StoreHooks'
import { setUserError } from '../../Store/user/userReducer'

const provider = new GoogleAuthProvider()
const auth = getAuth()

const useAuth = () => {
  const dispatch = useAppDispatch()
  const loginWithPopup = async () => {
    if (!auth.currentUser) {
      try {
        await signInWithPopup(auth, provider)
        return true
      } catch (err) {
        dispatch(setUserError())
        return false
      }
    }
  }

  return {
    loginWithPopup,
    signOut: () => signOut(auth),
  }
}

export default useAuth
