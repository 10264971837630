import { Alert, Snackbar } from '@mui/material'
import React from 'react'
import { useNotification } from '../../../Hooks/useNotification'

const Notification: React.FC<{
  type?: 'info' | 'error'
  message: string
  duration?: number
  id: string
}> = ({ type, message, duration, id }) => {
  const notification = useNotification()
  React.useEffect(() => {
    const timer = setTimeout(() => {
      notification.removeById(id)
    }, duration || 5000)
    return () => clearTimeout(timer)
  }, [duration, id])

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    // remove by id
    notification.removeById(id)
  }

  return (
    <Snackbar open={true} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={type || 'info'}
        sx={{ width: '100%', maxWidth: '300px' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Notification
