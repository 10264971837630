import { Box, Typography } from '@mui/material'
import animatedLogo from '../../Assets/brainanime2.gif'
import { extension_key } from '../../Config/extension_key'
import { sendExtMessage } from '../../Utils/extension/sendExtMessage'
import React, { useEffect } from 'react'
import useExtension from '../../Hooks/extension/useExtension'
import { getApi } from '../../Config/hosts'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const ExtensionConnector = () => {
  const { send } = useExtension()
  const navigate = useNavigate()
  useEffect(() => {
    axios.post(getApi('/api/user/ct')).then((res) => {
      const { token } = res.data
      send({ action: 'login', payload: token }).then((res) => {
        send({ action: 'closeMe' })
      })
    })
  }, [navigate])

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={animatedLogo} />
      <Typography variant="caption" sx={{ marginTop: '10px' }}>
        Please wait
      </Typography>
      <Typography variant="h6" sx={{}}>
        Connecting your extension
      </Typography>
    </Box>
  )
}

export default ExtensionConnector
