import { Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useMixpanel from '../../Hooks/useMixpanel'
import EarlyAccessWidget from '../../Components/EarlyAccess/EarlyAccessWidget'
const HomePage = () => {
  const navigate = useNavigate()
  const mixpanel = useMixpanel()

  const CTAClicked = () => {
    mixpanel.track('CTA_clicked', { page: 'Homepage' })
    navigate('/init')
  }
  return (
    <Box
      sx={{
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid container sx={{ minHeight: '100%' }}>
        <Grid item xs={12} md={12} alignItems="center" justifyContent="center">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              minHeight: '100%',
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: 'bold', maxWidth: '700px' }}>
              Talk, Don't Sequence
            </Typography>
            <Typography
              variant="h5"
              sx={{ maxWidth: '700px', color: '#454545', marginTop: '20px' }}
            >
              Create automated outreach flows in minutes that generate high-quality leads at scale.
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '5vh',
              }}
            >
              <EarlyAccessWidget />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{}}>
          <video
            autoPlay={true}
            muted
            style={{ marginTop: '50px', width: '80vw' }}
            src="https://firebasestorage.googleapis.com/v0/b/thebrain-2d7e3.appspot.com/o/story.mp4?alt=media&token=3cf80a39-8c04-4e7b-8f24-bbb46967cdec"
            controls
          >
            Your browser does not support the video tag.
          </video>
        </Box>
      </Box>
    </Box>
  )
}

export default HomePage
