import { getDownloadURL, uploadBytesResumable, ref, getStorage } from 'firebase/storage'
import { firebaseApp } from '../../Config/firebase'

const useStorageUpload = () => {
  const storage = getStorage(firebaseApp)

  const uploadFromUrl = (path: string, url: string, cb: (n: number) => void = (a: any) => null) => {
    const storageRef = ref(storage, path)

    return fetch(url, {})
      .then((res) => {
        return res.blob()
      })
      .then((blob) => {
        const uploadTask = uploadBytesResumable(storageRef, blob)

        //https://firebase.google.com/docs/storage/web/upload-files#web-version-9_6

        return new Promise((res, rej) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              cb(progress)
            },
            (error) => {
              rej(error)
            },
            () => {
              res(true)
            }
          )
        })
      })
      .catch((error) => {
        // console.error(error)
      })
  }

  const uploadFromBlob = (path: string, blob: Blob, cb: (n: number) => void = (a: any) => null) => {
    const storageRef = ref(storage, path)
    const uploadTask = uploadBytesResumable(storageRef, blob)

    //https://firebase.google.com/docs/storage/web/upload-files#web-version-9_6

    return new Promise((res, rej) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          if (!isNaN(progress)) cb(progress)
        },
        (error) => {
          rej(error)
        },
        () => {
          res(true)
        }
      )
    })
  }

  return {
    uploadFromUrl,
    uploadFromBlob,
  }
}

export default useStorageUpload
