import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../store'

export enum IAsyncState {
  LOADING = 'Loading',
  FETCHING = 'FETCHING',
  DONE = 'DONE',
}

type TBrainState = {
  id?: string
  fileId?: string
  goal?: string
  state: IAsyncState
  payload: any
  forms: {
    state: IAsyncState
    all: any[]
    selectedForm: string
  }
  currentFormQuestions: {
    state: IAsyncState
    questions: any[]
    selectedQuestion: string
    updated: number
  }
  currentFormMemories: {
    state: IAsyncState
    memories: any[]
    selectedMemory: string
    updated: number
  }
  currentFormReaches: {
    state: IAsyncState
    reaches: any[]
    updated: number
  }
}

const initialState: TBrainState = {
  state: IAsyncState.LOADING,
  payload: {},
  forms: {
    state: IAsyncState.LOADING,
    all: [],
    selectedForm: '',
  },
  currentFormQuestions: {
    state: IAsyncState.LOADING,
    questions: [],
    selectedQuestion: '',
    updated: Date.now(),
  },
  currentFormMemories: {
    state: IAsyncState.LOADING,
    memories: [],
    selectedMemory: '',
    updated: Date.now(),
  },
  currentFormReaches: {
    state: IAsyncState.LOADING,
    reaches: [],
    updated: Date.now(),
  },
}

export const brainSlice = createSlice({
  name: 'brain',
  initialState: initialState as TBrainState,
  reducers: {
    setBrainId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    setBrainFileId: (state, action: PayloadAction<string>) => {
      state.fileId = action.payload
    },
    setGoal: (state, action: PayloadAction<string>) => {
      state.goal = action.payload
    },
    setQuestionText: (state, action: PayloadAction<any>) => {
      state.currentFormQuestions.questions = state.currentFormQuestions.questions.map((q) => {
        if (q.id === action.payload.id) {
          return {
            ...q,
            text: action.payload.text,
            changed: true,
          }
        }
        return q
      })
    },
    setFormName: (state, action: PayloadAction<any>) => {
      state.forms.all = state.forms.all.map((f) => {
        if (f.id === action.payload.id) {
          return {
            ...f,
            formName: action.payload.formName,
            changed: true,
          }
        }
        return f
      })
    },
    resetSelectedForm: (state) => {
      state.forms.selectedForm = ''
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchFormsByBrain.fulfilled, (state, action) => {
      state.forms.all = action.payload
      state.forms.state = IAsyncState.DONE
    })
    builder.addCase(fetchFormsByBrain.pending, (state) => {
      state.forms.state = IAsyncState.LOADING
    })
    builder.addCase(setSelectedForm.fulfilled, (state, action) => {
      state.forms.selectedForm = action.payload.selectedFormId
      state.currentFormQuestions.questions = action.payload.questions
      state.currentFormQuestions.state = IAsyncState.DONE
      state.currentFormQuestions.updated = Date.now()
    })
    builder.addCase(setSelectedForm.pending, (state, action) => {
      state.currentFormQuestions.state = IAsyncState.LOADING
    })
    builder.addCase(changeQuestionOrder.pending, (state) => {
      state.currentFormQuestions.state = IAsyncState.LOADING
    })
    builder.addCase(deleteQuestion.pending, (state) => {
      state.currentFormQuestions.state = IAsyncState.LOADING
    })
    builder.addCase(updateQuestionText.pending, (state) => {
      state.currentFormQuestions.state = IAsyncState.LOADING
    })
    builder.addCase(updateQuestionText.fulfilled, (state, action: PayloadAction<any>) => {
      const updatedQuestion = action.payload.q
      state.currentFormQuestions.questions = state.currentFormQuestions.questions.map((q: any) => {
        if (q.id === updatedQuestion.id) {
          return updatedQuestion
        }
        return q
      })
      state.currentFormQuestions.state = IAsyncState.DONE
    })
    builder.addCase(updateFormText.pending, (state) => {
      state.forms.state = IAsyncState.LOADING
    })
    builder.addCase(updateFormText.fulfilled, (state, action: PayloadAction<any>) => {
      const updatedForm = action.payload.form
      state.forms.all = state.forms.all.map((form: any) => {
        if (form.id === updatedForm.id) {
          return updatedForm
        }
        return form
      })
      state.forms.state = IAsyncState.DONE
    })
    builder.addCase(deleteForm.pending, (state) => {
      state.currentFormQuestions.state = IAsyncState.LOADING
      state.forms.selectedForm = ''
    })

    builder.addCase(fetchBrainData.pending, (state) => {
      state.state = IAsyncState.LOADING
    })

    builder.addCase(fetchBrainData.fulfilled, (state, action: PayloadAction<any>) => {
      state.id = action.payload.id
      state.payload = action.payload
      state.state = IAsyncState.DONE
    })
    builder.addCase(getCurrentFormMemories.fulfilled, (state, action: PayloadAction<any>) => {
      state.currentFormMemories.memories = action.payload.memories
      state.currentFormMemories.state = IAsyncState.DONE
      state.currentFormMemories.updated = Date.now()
    })
    builder.addCase(getCurrentFormMemories.pending, (state) => {
      state.currentFormMemories.state = IAsyncState.LOADING
    })
    builder.addCase(getCurrentFormReaches.fulfilled, (state, action: PayloadAction<any>) => {
      state.currentFormReaches.reaches = action.payload.reaches
      state.currentFormReaches.state = IAsyncState.DONE
      state.currentFormReaches.updated = Date.now()
    })
    builder.addCase(getCurrentFormReaches.pending, (state) => {
      state.currentFormReaches.state = IAsyncState.LOADING
    })
  },
})

export const updateFormText = createAsyncThunk(
  'brain/updateFormText',
  async (id: string, thunkAPI) => {
    const form = (thunkAPI.getState() as any)?.brain?.forms?.all?.find((f: any) => f.id === id)
    const response = await axios.post(
      'https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/update',
      {
        id: form.id,
        formName: form.formName,
      }
    )
    return response.data
  }
)

export const updateQuestionText = createAsyncThunk(
  'brain/updateQuestionText',
  async (id: string, thunkAPI) => {
    const question = (thunkAPI.getState() as any)?.brain?.currentFormQuestions?.questions?.find(
      (q: any) => q.id === id
    )
    const response = await axios.post(
      'https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/question/update',
      {
        id: question.id,
        text: question.text,
      }
    )
    return response.data
  }
)

export const setSelectedForm = createAsyncThunk(
  'brain/setSelectedForm',
  async (formId: string, thunkAPI) => {
    const response = await axios.post(
      'https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/question/byform',
      {
        formId,
      }
    )
    return {
      selectedFormId: formId,
      questions: response.data?.questions.sort((a: any, b: any) => a.order - b.order) || [],
    }
  }
)

export const getCurrentFormMemories = createAsyncThunk(
  'brain/getCurrentFormMemories',
  async (fid: string | undefined, thunkAPI) => {
    const formId: any = fid || (thunkAPI.getState() as any)?.brain?.forms?.selectedForm
    const response = await axios.post(
      `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/${formId}/memories`
    )

    return {
      memories: response.data.memories,
    }
  }
)

export const getCurrentFormReaches = createAsyncThunk(
  'brain/getCurrentFormReaches',
  async (fid: string | undefined, thunkAPI) => {
    const formId: any = fid || (thunkAPI.getState() as any)?.brain?.forms?.selectedForm
    const response = await axios.post(
      `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/${formId}/reach`
    )

    return {
      reaches: response.data.contacts,
    }
  }
)

export const getCurrentFormQuestions = createAsyncThunk(
  'brain/getCurrentFormQuestions',
  async (_, thunkAPI) => {
    const formId: any = (thunkAPI.getState() as any)?.brain?.forms?.selectedForm
    thunkAPI.dispatch(setSelectedForm(formId))
    return
  }
)

export const fetchFormsByBrain = createAsyncThunk(
  'brain/fetchFormsByBrain',
  async (_, thunkAPI) => {
    const brainId: any = (thunkAPI.getState() as any)?.brain?.id
    if (brainId && brainId !== '') {
      const response = await axios.post(
        'https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/',
        {
          brainId,
        }
      )
      return response.data?.forms.sort((a: any, b: any) => a.createdAt - b.createdAt) || []
    } else {
      return []
    }
  }
)

export const deleteQuestion = createAsyncThunk(
  'brain/deleteQuestion',
  async (id: string, thunkAPI) => {
    const response = await axios.post(
      'https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/question/delete',
      {
        id,
      }
    )
    thunkAPI.dispatch(getCurrentFormQuestions())
    return
  }
)

export const deleteForm = createAsyncThunk('brain/deleteForm', async (id: string, thunkAPI) => {
  const response = await axios.post(
    'https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/delete',
    {
      id,
    }
  )
  thunkAPI.dispatch(fetchFormsByBrain())

  return
})

export const changeQuestionOrder = createAsyncThunk(
  'brain/changeFunctionOrder',
  async ({ id, order }: { id: string; order: number }, thunkAPI) => {
    const formId: any = (thunkAPI.getState() as any)?.brain?.forms?.selectedForm
    const response = await axios.post(
      'https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/question/switchorder',
      {
        q: {
          id,
          order,
          formId,
        },
      }
    )
    thunkAPI.dispatch(getCurrentFormQuestions())
    return
  }
)

export const addNewQuestion = createAsyncThunk(
  'brain/addNewQuestion',
  async (text: string, thunkAPI) => {
    const formId: any = (thunkAPI.getState() as any)?.brain?.forms?.selectedForm
    const brainId: any = (thunkAPI.getState() as any)?.brain?.id
    const order: number =
      (thunkAPI.getState() as any)?.brain?.currentFormQuestions.questions.length + 1

    if (formId && formId !== '') {
      const response = await axios.post(
        'https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/question/create',
        {
          brainId,
          formId,
          text,
          order,
        }
      )
      // thunkAPI.dispatch(setSelectedForm(response.data.id))
      return response.data
    } else {
      return []
    }
  }
)

export const createNewForm = createAsyncThunk('brain/createNewForm', async (_, thunkAPI) => {
  const brainId: any = (thunkAPI.getState() as any)?.brain?.id
  if (brainId) {
    const response = await axios
      .post('https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/create', {
        brainId,
        formName: 'Untitled',
      })
      .then((res) => {
        thunkAPI.dispatch(fetchFormsByBrain())
        return res
      })
    thunkAPI.dispatch(setSelectedForm(response.data.id))
    return response.data
  } else {
    return []
  }
})

export const fetchBrainData = createAsyncThunk(
  'brain/fetchBrainData',
  async (brainId: string, thunkAPI) => {
    if (brainId) {
      const response = await axios.post(
        `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/brain/${brainId}`
      )
      thunkAPI.dispatch(fetchFormsByBrain())
      return response.data
    } else {
      throw new Error('Invalid brain')
    }
  }
)

// Action creators are generated for each case reducer function
export const {
  setBrainId,
  setBrainFileId,
  setGoal,
  setQuestionText,
  setFormName,
  resetSelectedForm,
} = brainSlice.actions

export const selectBrain = (state: RootState) => state.brain
export const selectBrainId = (state: RootState) => state.brain.id
export const selectBrainGoal = (state: RootState) => state.brain.goal
export const isBrainFormsLoading = (state: RootState) =>
  state.brain.forms.state === IAsyncState.LOADING
export const selectBrainForms = (state: RootState) => state.brain.forms
export const selectCurrentForm = (state: RootState) =>
  state.brain.forms.all.find((f: any) => f.id === state.brain.forms.selectedForm)
export const selectCurrentFormQuestions = (state: RootState) =>
  state.brain.currentFormQuestions.questions
export const isLoadingFormQuestions = (state: RootState) =>
  state.brain.currentFormQuestions.state === IAsyncState.LOADING
export const isLoadingFormMemories = (state: RootState) =>
  state.brain.currentFormMemories.state === IAsyncState.LOADING
export const FormQuestionsUpdatedAt = (state: RootState) => state.brain.currentFormQuestions.updated
export const FormMemoriesUpdatedAt = (state: RootState) => state.brain.currentFormMemories.updated
export const selectCurrentFormMemories = (state: RootState) =>
  state.brain.currentFormMemories.memories
export const selectCurrentFormReaches = (state: RootState) => state.brain.currentFormReaches.reaches
export const selectReachesState = (state: RootState) => state.brain.currentFormReaches.state
export default brainSlice.reducer
