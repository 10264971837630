import { Box } from '@mui/system'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from '../Hooks/StoreHooks'
import { useCurrentPath } from '../Hooks/useCurrentPath'
import useMixpanel from '../Hooks/useMixpanel'
import UnAuthorized403 from '../Pages/UnAuthorized403'
import { isLoading, isLoggedIn } from '../Store/user/userReducer'
import Services from './Services'
import Header from './UI/Header/Header'

const PageContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const routeProps = useCurrentPath()
  const _isLoading = useAppSelector(isLoading)
  const _isLoggedIn = useAppSelector(isLoggedIn)
  const mixpanel = useMixpanel()
  React.useEffect(() => {
    mixpanel.track('page_loaded', { path: routeProps?.path })
  }, [routeProps, mixpanel])

  if (routeProps?.isPrivate) {
    if (_isLoading && !routeProps.path.startsWith('/signin')) {
      return <Navigate to="/signin?redirected=true" />
    }

    if (!_isLoggedIn) {
      // return (
      //   <Box sx={{ display: 'flex', minHeight: '100vh', minWidth: '100vw' }}>
      //     <Services />
      //     <UnAuthorized403 />
      //   </Box>
      // )
      if (!routeProps.path.startsWith('/signin')) return <Navigate to="/signin?redirected=true" />
      return <Navigate to="/signin" />
    }
  }

  return (
    <>
      <Services>
        <Box
          sx={{
            display: 'flex',
            minHeight: '100vh',
            minWidth: '100vw',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
          }}
        >
          {routeProps?.showHeader ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80px',
                }}
              >
                <Header />
              </Box>
              <Box sx={{ width: '100%', maxWidth: routeProps?.fullWidth ? '100vw' : '1200px' }}>
                {children}
              </Box>
            </>
          ) : (
            <Box sx={{ width: '100%' }}>{children}</Box>
          )}
        </Box>
      </Services>
    </>
  )
}

export default PageContainer
