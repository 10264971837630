import { Box, Typography } from '@mui/material'
import { useAppSelector } from '../../Hooks/StoreHooks'
import { selectBrain } from '../../Store/brain/brainReducer'
import { selectUser } from '../../Store/user/userReducer'
import { IAuthenticatedUser } from '../../Store/user/UserType'
import whitelogo from '../../Assets/whitelogo.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { drawerWidth, topBarHeight } from './Constants'

const BrainSelector = () => {
  const user = useAppSelector(selectUser)
  const brain = useAppSelector(selectBrain)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: `${drawerWidth + 40}px`,
        borderRight: '1px solid #444444',
        '&:hover': {
          background: '#000',
        },
        cursor: 'pointer',
      }}
    >
      <Box>
        <img src={whitelogo} style={{ height: `${topBarHeight / 2}px`, padding: '10px' }} />
      </Box>
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'flex-start' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            {brain.payload?.name || 'Unknown brain'}
          </Typography>
          <Typography variant="caption" sx={{ fontSize: '8px' }}>
            {(user as IAuthenticatedUser).data.email}
          </Typography>
        </Box>
      </Box>
      <Box>
        <KeyboardArrowDownIcon sx={{ padding: '10px', fontSize: '1.2rem' }} />
      </Box>
    </Box>
  )
}

export default BrainSelector
