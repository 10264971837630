import { Box, Button, Checkbox, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import {
  IAsyncState,
  getCurrentFormReaches,
  selectBrain,
  selectCurrentForm,
  selectCurrentFormReaches,
  selectReachesState,
} from '../../Store/brain/brainReducer'
import { Filters } from './Filters'
import BrainLoader from '../Loaders/BrainLoader'
import { useNavigate, useParams } from 'react-router-dom'
import { useNotification } from '../../Hooks/useNotification'
import {
  CURRENT_DASHBOARD_TAB,
  setCurrentDashboardTab,
} from '../../Store/dashboard/DashboardReducer'
import { selectUser } from '../../Store/user/userReducer'
import { IAuthenticatedUser } from '../../Store/user/UserType'
import useMixpanel from '../../Hooks/useMixpanel'

const ReachTab = () => {
  const [targetInput, setTargetInput] = React.useState('')
  const [filteredContacts, setFilteredContacts] = React.useState<any[]>([])
  const [selected, setSelected] = React.useState<string[]>([])
  const brain = useAppSelector(selectBrain)
  const currentUser = useAppSelector(selectUser)
  const contacts = useAppSelector(selectCurrentFormReaches)
  const currentForm = useAppSelector(selectCurrentForm)
  const dispatch = useAppDispatch()
  const { reachId } = useParams()
  const notify = useNotification()
  const navigate = useNavigate()
  const mixpanel = useMixpanel()

  const addSelected = (id: string) => {
    if (selected.find((a: string) => a === id) === undefined) setSelected((prev) => [...prev, id])
  }
  const removeSelected = (id: string) => {
    setSelected(selected.filter((o) => o !== id))
  }

  const performFiltering = (newFilters: any) => {
    const newMemories = contacts.filter((m: any) => {
      for (let f of newFilters) {
        if (!f.selectedValues.includes(m[f.selectedProperty.key])) return false
      }
      return true
    })
    setFilteredContacts(newMemories)
  }

  return (
    <>
      <Box
        sx={{
          marginTop: '20px',
          marginBottom: '20px',
          border: '1px solid #e2e2e2',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <Box>
          <Typography
            variant="body1"
            sx={{ fontWeight: '520', color: '#8f8f8f', fontSize: '13px' }}
          >
            Contacts importer
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '10px',
            flexDirection: 'row',
          }}
        >
          <Typography sx={{ flex: 1, padding: '10px' }} variant="caption">
            Role description
          </Typography>
          <TextField
            sx={{ flex: 1 }}
            value={targetInput}
            onChange={(e) => setTargetInput(e.target.value)}
            size="small"
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ flex: 1, padding: '5px' }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                if (targetInput !== '')
                  axios
                    .post(
                      `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/brain/${brain.id}/contacts/filter`,
                      {
                        formId: brain.forms.selectedForm,
                        param: 'occupation',
                        value: targetInput,
                      }
                    )
                    .then(() => {
                      dispatch(getCurrentFormReaches())
                    })

                setTargetInput('')
              }}
            >
              Import contacts
            </Button>
          </Box>
        </Box>
      </Box>
      <Filters
        data={contacts}
        initialFilters={
          reachId
            ? [
                {
                  selectedValues: [reachId],
                  selectedProperty: {
                    key: 'id',
                    type: 'string',
                  },
                  id: reachId,
                  filterer: 'oneOf',
                },
              ]
            : undefined
        }
        onChange={(newFilters: any) => {
          performFiltering(newFilters)
        }}
      />
      <Box>
        <Button
          variant="contained"
          sx={{ margin: '10px' }}
          onClick={() => {
            setSelected([])
            axios
              .post(`https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/reach/delete`, {
                contacts: selected,
              })
              .then(() => {
                dispatch(getCurrentFormReaches())
              })
          }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          sx={{ margin: '10px' }}
          onClick={() => {
            const settings = currentForm?.settings || {}
            mixpanel.track('clicked_schedule_a_meeting', { ...settings })
            if (
              !validSettingsObject(settings, [
                'segmentDescription',
                'productDescription',
                'meetingLength',
                'meetingWindow',
                'meetingPurpose',
                'challenges',
                'valueProps',
              ])
            ) {
              notify.add({
                type: 'error',
                message: 'Please fill all the campaign settings\nbefore scheduling a reach',
              })
              dispatch(setCurrentDashboardTab(CURRENT_DASHBOARD_TAB.SETTINGS))
              return
            }

            const userSettings = (currentUser as IAuthenticatedUser)?.data?.settings
            if (userSettings === undefined || !validSettingsObject(userSettings, ['title'])) {
              notify.add({
                type: 'error',
                message:
                  'Please fill your SDR settings and save them\n before scheduling a meeting',
              })
              navigate('/user/settings')
              return
            }
            setSelected([])

            axios
              .post(
                `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/${brain.forms.selectedForm}/reach/schedule`,
                {
                  contacts: selected,
                  type: 'schedule_meeting',
                }
              )
              .then(() => {
                mixpanel.track('started_reach_scheduling', { contacts })
                dispatch(getCurrentFormReaches())
              })
              .catch((err) => {
                // if quota error dislpay a notification
                if (err.response.status === 429) {
                  notify.add({ message: err.response.data.error, type: 'error' })
                }
              })
          }}
        >
          Schedule a meeting
        </Button>
        <Button
          variant="contained"
          sx={{ margin: '10px' }}
          onClick={() => {
            setSelected([])
            axios
              .post(
                `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/form/${brain.forms.selectedForm}/reach/pause`,
                {
                  contacts: selected,
                  type: 'schedule_meeting',
                }
              )
              .then(() => {
                dispatch(getCurrentFormReaches())
              })
          }}
        >
          Pause
        </Button>
      </Box>
      <Box>
        <Typography variant="caption">
          {contacts.length} contacts / {selected.length} selected
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          background: '#f2f2f2',
          border: '1px solid #e2e2e2',
          display: 'flex',
          padding: '5px',
        }}
      >
        <Box sx={{ width: '25%', display: 'flex', alignItems: 'center' }}>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setSelected(contacts.map((c: any) => c.id))
              } else {
                setSelected([])
              }
            }}
          />
        </Box>
        <Box sx={{ width: '25%', display: 'flex', alignItems: 'center' }}>Name</Box>
        <Box sx={{ width: '25%', display: 'flex', alignItems: 'center' }}>Status</Box>
        <Box sx={{ width: '25%', display: 'flex', alignItems: 'center' }}>Source</Box>
        <Box sx={{ width: '25%', display: 'flex', alignItems: 'center' }}>Occupation</Box>
      </Box>
      {filteredContacts.map((c: any, i) => (
        <Contact
          contact={c}
          key={i}
          addSelected={() => addSelected(c.id)}
          removeSelected={() => removeSelected(c.id)}
          isSelected={selected.find((a) => a === c.id) !== undefined}
        />
      ))}
    </>
  )
}

const Contact: React.FC<any> = ({ contact, addSelected, removeSelected, isSelected }) => {
  const reachesState = useAppSelector(selectReachesState)

  if (reachesState !== IAsyncState.DONE) return <BrainLoader text="Loading reaches" />
  return (
    <>
      <Box
        sx={{
          width: '100%',
          background: '#fff',
          border: '1px solid #e2e2e2',
          display: 'flex',
          padding: '5px',
        }}
      >
        <Box sx={{ width: '25%' }}>
          <Checkbox
            checked={isSelected}
            onChange={() => {
              if (!isSelected) {
                addSelected()
              } else {
                removeSelected()
              }
            }}
          />
        </Box>
        <Box sx={{ width: '25%' }}>{contact?.name}</Box>
        <Box sx={{ width: '25%' }}>{contact?.status}</Box>
        <Box sx={{ width: '25%' }}>
          {contact?.source === 'linkedin_contact' ? 'Linkedin' : 'Unknown'}
        </Box>
        <Box sx={{ width: '25%' }}>{contact?.occupation}</Box>
      </Box>
    </>
  )
}

function validSettingsObject(obj: Record<string, unknown>, allowedKeys: string[]): boolean {
  for (let i = 0; i < allowedKeys.length; i++) {
    if (!(allowedKeys[i] in obj)) {
      return false
    }
  }
  return true
}

export default ReachTab
