import {
  addNotification,
  Notification,
  removeNotificationById,
} from '../Store/notifications/notificationReducer'
import { useAppDispatch } from './StoreHooks'

export const useNotification = () => {
  const dispatch = useAppDispatch()

  const add = (n: Omit<Notification, 'id'>) => dispatch(addNotification(n))
  const removeById = (nid: string) => dispatch(removeNotificationById(nid))

  return {
    add,
    removeById,
  }
}
