import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Header from '../InternalHeader/Header'
import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import { saveUserDetails, selectUser } from '../../Store/user/userReducer'
import { IAuthenticatedUser } from '../../Store/user/UserType'
import RangeSelector from '../Inputs/RangeSelector'
import { useNotification } from '../../Hooks/useNotification'
import axios from 'axios'
import { resetSelectedForm } from '../../Store/brain/brainReducer'

const UserSettings = () => {
  const currentUser = useAppSelector(selectUser)
  const notify = useNotification()
  const dispatch = useAppDispatch()
  const [userSettings, setUserSettings] = React.useState({
    title: (currentUser as IAuthenticatedUser)?.data?.settings?.title || '',
    friendRequestsLimit:
      (currentUser as IAuthenticatedUser)?.data?.settings?.friendRequestsLimit || 5,
    dailyActionsLimit: (currentUser as IAuthenticatedUser)?.data?.settings?.dailyActionsLimit || 50,
  })
  React.useEffect(() => {
    dispatch(resetSelectedForm())
  }, [dispatch])

  const handleChange = (elementName: string) => (event: any) => {
    setUserSettings({
      ...userSettings,
      [elementName]: event.target.value,
    })
  }

  const updateUserSettings = () => {
    notify.add({
      type: 'info',
      message: 'Updating user settings.. ',
    })
    axios
      .post(`https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/user/settings/update`, {
        settings: userSettings,
      })
      .then(() => {
        notify.add({ type: 'info', message: 'User settings updated..' })
        dispatch(saveUserDetails())
      })
      .catch(() => {
        notify.add({ type: 'error', message: 'Error updating user settings' })
        dispatch(saveUserDetails())
      })
  }
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
        }}
      >
        <Sidebar />
        <Box
          sx={{ display: 'flex', width: '100%', flex: 1, padding: '20px', flexDirection: 'column' }}
        >
          <Box
            sx={{
              padding: '0px',
              fontSize: '1.4rem',
              fontWeight: 'bold',
            }}
          >
            User Settings
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
            <Box>
              <Typography variant="h6">Title</Typography>
            </Box>
            <Box>
              <Typography variant="caption" sx={{ color: 'gray', fontStyle: 'italic' }}>
                What is your title ?
              </Typography>
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                label="Your position, ex: SDR at PiedPiper"
                variant="outlined"
                sx={{ width: '100%' }}
                value={userSettings.title}
                onChange={handleChange('title')}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
            <Box sx={{}}>
              <RangeSelector
                label="Daily friend requests"
                min={0}
                max={20}
                onChange={(x: any) => {
                  setUserSettings({
                    ...userSettings,
                    friendRequestsLimit: x[0],
                  })
                }}
                initialValue={[userSettings?.friendRequestsLimit || 5]}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
            <Box sx={{}}>
              <RangeSelector
                label="Daily actions limit"
                min={0}
                max={70}
                onChange={(x: any) => {
                  setUserSettings({
                    ...userSettings,
                    dailyActionsLimit: x[0],
                  })
                }}
                initialValue={[userSettings?.dailyActionsLimit || 50]}
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            <Button onClick={() => updateUserSettings()} variant="contained" fullWidth>
              Save Settings
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UserSettings
