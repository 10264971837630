import { Box, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'
import animatedLogo from '../../Assets/brainanime2.gif'

const loaded = false
const SessionCmp = () => {
  const [fileURL, setFileURL] = React.useState<string>()
  const { id } = useParams()
  React.useEffect(() => {
    axios
      .post(`https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/session/${id}`)
      .then((res) => {
        setFileURL(res.data.fileUrl)
      })
  }, [id])
  if (!fileURL)
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={animatedLogo} />
        <Typography variant="caption" sx={{ marginTop: '10px' }}>
          Please wait
        </Typography>
        <Typography variant="h6" sx={{}}>
          Loading session
        </Typography>
      </Box>
    )

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <audio controls style={{ width: '100%' }}>
        <source src={fileURL} type="audio/webm" />
        Your browser does not support the audio element.
      </audio>
    </Box>
  )
}

export default SessionCmp
