import React from 'react'

import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import useMixpanel from '../../Hooks/useMixpanel'
import { useSearchParams } from 'react-router-dom'

const EarlyAccessWidget = () => {
  const [registered, setRegistered] = React.useState<boolean>(false)
  const [userData, setUserData] = React.useState<any>({ name: '', email: '' })
  const mixpanel = useMixpanel()
  const [searchParams] = useSearchParams()

  React.useEffect(() => {
    if (searchParams.get('fullaccess')) {
      const element = document.getElementById('fullaccess')
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [searchParams])
  const register = () => {
    setRegistered(true)
    mixpanel.track('user_registered', { name: userData.name, email: userData.email })
  }
  if (registered)
    return (
      <Box
        sx={{
          marginTop: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #ADD8E6',
          borderRadius: '10px',
          padding: '20px',
          flexDirection: 'column',
          background: 'rgba(173, 216, 230, 0.1)',
          width: '100%',
        }}
      >
        Thank you for your registration, we will contact you very soon.
      </Box>
    )

  return (
    <Box
      sx={{
        marginTop: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          border: '1px solid #ADD8E6',
          borderRadius: '10px',
          padding: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          background: 'rgba(173, 216, 230, 0.1)',
          width: '100%',
        }}
      >
        <TextField
          onChange={(e) => setUserData((data: any) => ({ ...data, email: e.target.value }))}
          value={userData?.email}
          size="medium"
          type="outlined"
          placeholder="email"
        />
        <Button
          variant="contained"
          sx={{ height: '54px', margin: '10px', fontWeight: 'bold' }}
          onClick={() => register()}
        >
          Request early access
        </Button>
      </Box>
    </Box>
  )
}

export default EarlyAccessWidget
