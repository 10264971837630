import { Box } from '@mui/system'
import React from 'react'
import RecordingWidget, { IStep } from '../Components/RecordingWidget'

const Answer = () => {
  return (
    <Box sx={{ display: 'flex', width: '100vw', height: '100vh' }}>
      <RecordingWidget initialStep={IStep.GetUserMedia} />
    </Box>
  )
}

export default Answer
