import React from 'react'
import { Box } from '@mui/system'
import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import { selectCurrentForm } from '../../Store/brain/brainReducer'
import brainanime from '../../Assets/brainanime2.gif'
import { Typography } from '@mui/material'
import FormViewHeader from './FormViewHeader'
import SettingsTab from './SettingsTab'
import {
  CURRENT_DASHBOARD_TAB,
  selectDashboard,
  setCurrentDashboardTab,
} from '../../Store/dashboard/DashboardReducer'
import InsightsTab from './InsightsTab'
import ReachTab from './ReachTab'

const FormContentView = () => {
  const currentForm = useAppSelector(selectCurrentForm)
  const dispatch = useAppDispatch()
  const { currentTab } = useAppSelector(selectDashboard)

  const setCurrentTab = (tab: CURRENT_DASHBOARD_TAB) => {
    dispatch(setCurrentDashboardTab(tab))
  }

  if (!currentForm) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '70vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={brainanime} />
        <Typography variant="caption"> Select or create a campaign to get started</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <FormViewHeader />
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            background: '#fff',
            border: '1px solid #e9e9e9',
            borderRadius: '5px',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              '&:hover': { background: 'rgb(173, 216, 230, 0.3)' },
              padding: '10px',
              cursor: 'pointer',
              background: currentTab === CURRENT_DASHBOARD_TAB.SETTINGS ? '#e9e9e9' : '',
              borderRight: '1px solid #e9e9e9',
            }}
            onClick={() => setCurrentTab(CURRENT_DASHBOARD_TAB.SETTINGS)}
          >
            <Typography
              variant="caption"
              sx={{
                fontWeight: currentTab === CURRENT_DASHBOARD_TAB.SETTINGS ? 'bold' : 'regular',
              }}
            >
              Settings
            </Typography>
          </Box>
          <Box
            sx={{
              '&:hover': { background: 'rgb(173, 216, 230, 0.3)' },
              padding: '10px',
              cursor: 'pointer',
              background: currentTab === CURRENT_DASHBOARD_TAB.REACH ? '#e9e9e9' : '',
            }}
            onClick={() => setCurrentTab(CURRENT_DASHBOARD_TAB.REACH)}
          >
            <Typography
              variant="caption"
              sx={{
                fontWeight: currentTab === CURRENT_DASHBOARD_TAB.REACH ? 'bold' : 'regular',
              }}
            >
              Reach
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ height: '10px' }}></Box>
      {currentTab == CURRENT_DASHBOARD_TAB.SETTINGS && <SettingsTab />}
      {currentTab === CURRENT_DASHBOARD_TAB.REACH && <ReachTab />}
    </Box>
  )
}

export default FormContentView
