// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD7WKxPmzWidzVIt2MHHAS-0zynnckhNNs',
  authDomain: 'thebrain-2d7e3.firebaseapp.com',
  projectId: 'thebrain-2d7e3',
  storageBucket: 'thebrain-2d7e3.appspot.com',
  messagingSenderId: '243003051819',
  appId: '1:243003051819:web:dda7690aa7195a04b8bf6e',
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const firestore = getFirestore(firebaseApp)
