import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../store'

export enum IReachAutoPilotState {
  PAUSED = 'paused',
  RUNNING = 'running',
  WAITING = 'waiting',
}

type TReachState = {
  reaches: any[]
  currentReach?: any
  state: IReachAutoPilotState
}

const initialState: TReachState = {
  reaches: [],
  currentReach: undefined,
  state: IReachAutoPilotState.PAUSED,
}

export const reachManagerSlice = createSlice({
  name: 'reachmanager',
  initialState: initialState as TReachState,
  reducers: {
    addReaches: (state, action: PayloadAction<any[]>) => {
      const shouldMoveCursor = state.reaches.length === 0

      state.reaches = [...state.reaches, ...action.payload]

      if (shouldMoveCursor && action.payload.length > 0) {
        state.currentReach = action.payload[0]
      }
      console.log('added reaches')
      console.log(state.reaches)
      return state
    },
    deleteReaches: (state, action: PayloadAction<any[]>) => {
      state.reaches = state.reaches.filter((r) => !action.payload.includes(r.id))
    },
    moveCurrentReachCursor: (state) => {
      console.log('move cursor')
      // remove previous reach
      state.reaches = state.reaches.filter((r) => r.id !== state.currentReach.id)
      // move cursor
      if (state.state !== IReachAutoPilotState.PAUSED)
        if (state.reaches?.[0]) {
          state.currentReach = state.reaches?.[0]
        } else {
          state.state = IReachAutoPilotState.WAITING
          state.currentReach = undefined
        }
      else state.currentReach = undefined
    },
    startCurrentReachFlow: (state) => {
      if (state.state !== IReachAutoPilotState.PAUSED) {
        state.currentReach = { ...state.currentReach, onProgress: true }
        state.state = IReachAutoPilotState.RUNNING
      }
    },
    runReachAutoPilot: (state) => {
      state.state = IReachAutoPilotState.WAITING
    },
    pauseReachAutoPilot: (state) => {
      state.state = IReachAutoPilotState.PAUSED
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  addReaches,
  deleteReaches,
  startCurrentReachFlow,
  moveCurrentReachCursor,
  runReachAutoPilot,
  pauseReachAutoPilot,
} = reachManagerSlice.actions

export const fetchNewScheduledReaches = createAsyncThunk(
  'reachmanager/fetchNewScheduledReaches',
  async (_, thunkAPI) => {
    const state: any = thunkAPI.getState()
    if (state.brain.state === 'DONE' && state.reachManager.state !== IReachAutoPilotState.PAUSED) {
      const brainId = state.brain.id
      const currentReaches = state.reachManager.reaches
      axios
        .get(
          `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/brain/${brainId}/reach/scheduled`
        )
        .then((res: any) => {
          const newSchedules = res.data.reach.filter((reach: any) => {
            return !currentReaches.map((r: any) => r.id).includes(reach.id)
          })
          const deletedSchedules = currentReaches
            .filter(
              (r: any) =>
                !res.data.reach.map((r: any) => r.id).includes(r.id) && r.id !== '*custom*'
            )
            .map((r: any) => r.id)
          if (deletedSchedules.length > 0) thunkAPI.dispatch(deleteReaches(deletedSchedules))
          if (newSchedules.length > 0) {
            thunkAPI.dispatch(addReaches(newSchedules))
          }
          return
        })
      // fetch and add new
    }
  }
)

export const selectScheduledReaches = (state: RootState) => state.reachManager
export const selectCurrentReach = (state: RootState) => state.reachManager.currentReach
export const selectReachManagerState = (state: RootState) => state.reachManager.state
export const selectScheduledReachesCount = (state: RootState) => state.reachManager.reaches.length
export const selectReachQueue = (state: RootState) => state.reachManager.reaches
export default reachManagerSlice.reducer
