import { Badge, Box, Typography } from '@mui/material'
import React from 'react'
import useAuth from '../../Hooks/firebase/useAuth'
import BrainSelector from './BrainSelector'
import { topBarHeight } from './Constants'
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive'
import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import {
  IReachAutoPilotState,
  pauseReachAutoPilot,
  runReachAutoPilot,
  selectReachManagerState,
  selectScheduledReachesCount,
} from '../../Store/reachmanager/reachManagerReducer'

const Header = () => {
  return (
    <Box
      sx={{
        background: '#2c2c2c',
        width: '100vw',
        height: `${topBarHeight}px`,
        zIndex: 99999,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        color: '#fff',
      }}
    >
      <BrainSelector />
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', padding: '10px' }}>
        <UserSettings />
      </Box>
    </Box>
  )
}

const UserSettings = () => {
  const auth = useAuth()
  const reachCount = useAppSelector(selectScheduledReachesCount)
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          margin: '20px',
          '&:hover': { background: '#000', padding: '5px', borderRadius: '5px' },
          cursor: 'pointer',
        }}
      >
        <Badge
          overlap="rectangular"
          badgeContent={reachCount}
          color="primary"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{ '& .MuiBadge-badge': { fontSize: 10, height: 15, minWidth: 15 } }}
        ></Badge>
      </Box>

      <Box
        sx={{ '&:hover': { background: '#000', padding: '5px', borderRadius: '5px' } }}
        onClick={() => auth.signOut()}
      >
        <Typography variant="caption" sx={{ cursor: 'pointer' }}>
          Signout
        </Typography>
      </Box>
    </Box>
  )
}

const AutoPilotHint = () => {
  const state = useAppSelector(selectReachManagerState)
  const dispatch = useAppDispatch()
  if (state === IReachAutoPilotState.PAUSED)
    return (
      <>
        <AirplanemodeInactiveIcon onClick={() => dispatch(runReachAutoPilot())} />
      </>
    )

  if (state === IReachAutoPilotState.WAITING)
    return (
      <>
        <AirplanemodeActiveIcon onClick={() => dispatch(pauseReachAutoPilot())} />
      </>
    )

  return (
    <>
      <AirplanemodeActiveIcon
        style={{
          animation: 'rotation infinite 3s linear',
        }}
        onClick={() => dispatch(pauseReachAutoPilot())}
      />
    </>
  )
}
export default Header
