import React from 'react'
import { Box } from '@mui/system'
import { MenuItem, Select } from '@mui/material'

export const Filterer: React.FC<any> = ({ type, onChange, filterer }) => {
  const options = []
  if (type === 'string') options.push('oneOf')
  if (type === 'number') options.push('>=', '<=', '<', '>', '=')
  if (type === 'boolean') options.push('is')

  if (options.length === 0) return <></>
  return (
    <Box>
      {' '}
      <Select
        size="small"
        value={filterer}
        onChange={(e: any) => {
          onChange(e.target.value)
        }}
        autoWidth
      >
        {options.map((o: any, idx: number) => {
          return (
            <MenuItem key={idx} value={o}>
              {o}
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}
