import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import animatedLogo from '../Assets/brainanime2.gif'

import { isPendingStream, isReadyStream, IStreamStates } from '../Hooks/mediaStream/useMediaStream'
import { UserMediaContext } from '../Contexts/UserMediaContext'
import Loading from './Loading'
import { FormView } from './Form/FormView'
import { useNavigate } from 'react-router-dom'
import Header from './UI/Header/Header'
import { useCountDown } from '../Hooks/useCountDown'

export enum IStep {
  Initial = 0,
  GetUserMedia = 1,
  Ready = 2,
  Error = 3,
}

const RecordingWidget: React.FC<{ initialStep?: IStep }> = ({ initialStep }) => {
  const [step, setStep] = React.useState<IStep>(initialStep || IStep.Initial)
  const navigate = useNavigate()

  const router = React.useMemo(() => {
    switch (step) {
      case IStep.Initial:
        return <InitialView gotoNextStep={() => navigate('/answer')} />
      case IStep.GetUserMedia:
        return <UserMediaView gotoNextStep={() => setStep(IStep.Ready)} />
      case IStep.Ready:
        return <FormView gotoNextStep={() => setStep(IStep.Error)} />
      case IStep.Error:
        return <></>
    }
  }, [step])

  return (
    <>
      {/* <Box
        sx={{
          width: { sm: '100%' },
          margin: '10px',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      > */}
      {router}
      {/* </Box> */}
    </>
  )
}

const InitialView: React.FC<any> = ({ gotoNextStep }) => {
  return (
    <Box
      sx={{
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Header />
      <Box sx={{ maxWidth: '1200px', marginTop: '150px' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', maxWidth: '800px' }}>
          How it will work ?
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '50px',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          maxWidth: '1200px',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h5">
          - We will ask for permissions to use your microphone (we will use it only during the
          session)
        </Typography>
        <Typography variant="h5">- You will be asked questions, answer them verbally</Typography>
        <Typography variant="h5">
          - Do not think too much when answering, just speak up your mind
        </Typography>
        <Typography variant="h5">
          - You have a maximum amount of 2 minutes to answer each question, when it is finished it
          will move to the next question
        </Typography>
        <Typography variant="h5">
          - If you finished answering before 2 minutes just click next question
        </Typography>
        <Typography variant="h5">
          - If you don't know the answer just say 'I don't know' and click next question
        </Typography>
        <Typography variant="h5">
          - When you finish answering all the question wait for the app to upload all your results,
          digest the information and generate recommendations for you
        </Typography>
        <Typography variant="h5">
          - When you are ready, click the button bellow to start the session
        </Typography>
        <Button
          sx={{ display: 'flex', width: '100%' }}
          variant="contained"
          onClick={() => gotoNextStep()}
        >
          I'm ready Let's start the session
        </Button>
      </Box>
    </Box>
  )
}

const UserMediaView: React.FC<any> = ({ gotoNextStep }) => {
  const umCtx = React.useContext(UserMediaContext)

  React.useEffect(() => {
    if (isReadyStream(umCtx.stream)) {
      gotoNextStep()
    }
    if (isPendingStream(umCtx.stream)) {
      umCtx.invoke()
    }
  }, [umCtx.stream])

  switch (umCtx.stream.state) {
    case IStreamStates.Error:
      return (
        <>
          <Typography variant="caption">
            You should allow recording camera and mic. remove the recording block from your browser
            and try again.
          </Typography>
        </>
      )
    case IStreamStates.Loading:
      return <Loading />
    case IStreamStates.Pending:
      return <Loading />
    case IStreamStates.Ready:
      return (
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <img src={animatedLogo} />
          <Typography variant="caption" sx={{ marginTop: '10px' }}>
            Ready ?
          </Typography>
          <Typography variant="h6" sx={{}}>
            The recording will now start
          </Typography>
        </Box>
      )
  }
}

export default RecordingWidget
