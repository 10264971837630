export function getObjectsArrayKeys(input: { [key: string]: any }[]): any[] {
  const keys: any[] = []
  const options: any[] = []
  // Loop through each object in the input array
  for (const obj of input) {
    Object.keys(obj).map((key) => {
      if (!keys.includes(key)) {
        if (obj[key] !== null) {
          keys.push(key)
          options.push({ key, type: typeof obj[key] })
        }
      }
    })
  }

  return options
}
