import React from 'react'
import { Box } from '@mui/system'
import {
  deleteForm,
  FormMemoriesUpdatedAt,
  FormQuestionsUpdatedAt,
  getCurrentFormMemories,
  getCurrentFormQuestions,
  isLoadingFormMemories,
  isLoadingFormQuestions,
  selectBrain,
  selectCurrentForm,
  setFormName,
  updateFormText,
} from '../../Store/brain/brainReducer'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import Moment from 'react-moment'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ShareIcon from '@mui/icons-material/Share'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import RefreshIcon from '@mui/icons-material/Refresh'
import SaveIcon from '@mui/icons-material/Save'
import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import { CURRENT_DASHBOARD_TAB, selectDashboard } from '../../Store/dashboard/DashboardReducer'
import axios from 'axios'

const FormViewHeader = () => {
  const dispatch = useAppDispatch()
  const currentForm = useAppSelector(selectCurrentForm)
  const isLoadingQuestions = useAppSelector(isLoadingFormQuestions)
  const isLoadingMemories = useAppSelector(isLoadingFormMemories)
  const updatedAtQuestions = useAppSelector(FormQuestionsUpdatedAt)
  const updatedAtMemories = useAppSelector(FormMemoriesUpdatedAt)

  const { currentTab } = useAppSelector(selectDashboard)

  let updatedAt = updatedAtQuestions
  if (currentTab === CURRENT_DASHBOARD_TAB.MEMORIES) {
    updatedAt = updatedAtMemories
  }
  // form menu handling
  const [formMenuAnchorEl, setFormMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const formMenuOpen = Boolean(formMenuAnchorEl)
  const handleFormMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFormMenuAnchorEl(event.currentTarget)
  }
  const handleFormMenuClose = () => {
    setFormMenuAnchorEl(null)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <TextField
          value={currentForm.formName}
          variant="standard"
          inputProps={{
            style: {
              padding: '0px',
              color: currentForm.changed && 'gray',
              fontSize: '1.4rem',
              fontWeight: 'bold',
            },
          }}
          onChange={(e) => {
            dispatch(setFormName({ id: currentForm.id, formName: e.target.value }))
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              dispatch(updateFormText(currentForm.id))
              // Do code here
              ev.preventDefault()
            }
          }}
          InputProps={{ disableUnderline: true }}
        />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          {currentForm.changed && (
            <SaveIcon
              sx={{
                color: 'gray',
                fontSize: '1.2rem',
                cursor: 'pointer',
                margin: '5px',
                '&:hover': { color: 'black' },
              }}
              onClick={() => {
                dispatch(updateFormText(currentForm.id))
              }}
            />
          )}
          {(currentTab === CURRENT_DASHBOARD_TAB.QUESTIONS && isLoadingQuestions) ||
          (currentTab === CURRENT_DASHBOARD_TAB.MEMORIES && isLoadingMemories) ? (
            <CircularProgress size="1.2rem" />
          ) : (
            <>
              <RefreshIcon
                sx={{
                  fontSize: '1.2rem',
                  color: 'gray',
                  margin: '2px',
                  cursor: 'pointer',
                  '&:hover': { color: 'black' },
                }}
                onClick={() => {
                  if (currentTab === CURRENT_DASHBOARD_TAB.MEMORIES)
                    dispatch(getCurrentFormMemories())
                  else dispatch(getCurrentFormQuestions())
                }}
              />
              <Typography variant="caption" sx={{ color: 'gray' }}>
                Updated{' '}
                <Moment interval={120 * 1000} fromNow>
                  {updatedAt}
                </Moment>
              </Typography>
            </>
          )}
          <MoreVertIcon
            sx={{
              margin: '10px',
              fontSize: '1.2rem',
              color: 'gray',
              cursor: 'pointer',
              '&:hover': { color: 'black' },
            }}
            onClick={(e: any) => {
              handleFormMenuClick(e)
            }}
          />
          <Menu
            id="form-menu"
            anchorEl={formMenuAnchorEl}
            open={formMenuOpen}
            onClose={handleFormMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            disableAutoFocusItem
          >
            <MenuItem
              onClick={() => {
                handleFormMenuClose()
                dispatch(deleteForm(currentForm.id))
              }}
            >
              <ListItemIcon>
                <DeleteIcon sx={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="caption">Delete</Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigator.clipboard
                  .writeText(window.location.host + '/mentor/' + currentForm.id)
                  .then(() => {})
                handleFormMenuClose()
              }}
            >
              <ListItemIcon>
                <ShareIcon sx={{ fontSize: '1.2rem' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="caption">Share</Typography>
              </ListItemText>
            </MenuItem>
            <ZoomItem />
          </Menu>
        </Box>
      </Box>
    </>
  )
}

const ZoomItem = () => {
  const [open, setOpen] = React.useState(false)
  const [zoomLink, setZoomLink] = React.useState('')
  const currentForm = useAppSelector(selectCurrentForm)
  const brain = useAppSelector(selectBrain)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const sendZoom = () => {
    axios.post('https://bb-zoom-z5qcctiqya-uc.a.run.app/recorder', {
      zoomURL: zoomLink,
      formId: currentForm.id,
      brainId: brain.id,
    })
    setZoomLink('')
  }
  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <VideoCallIcon sx={{ fontSize: '1.2rem' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="caption">Zoom</Typography>
        </ListItemText>
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Zoom url</DialogTitle>
        <DialogContent>
          <DialogContentText>Add zoom meeting url for the bot to join.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Zoom meeting link"
            type="zoomlink"
            fullWidth
            variant="standard"
            value={zoomLink}
            onChange={(e) => setZoomLink(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              sendZoom()
              handleClose()
            }}
          >
            SendZoom
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FormViewHeader
