import React from 'react'
import { Box } from '@mui/system'

import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import {
  fetchFormsByBrain,
  getCurrentFormMemories,
  getCurrentFormReaches,
  IAsyncState,
  selectBrain,
  setSelectedForm,
} from '../../Store/brain/brainReducer'

import LoadingBrain from './LoadingBrain'
import FormContentView from './FormContentView'
import Sidebar from '../Sidebar/Sidebar'
import Header from '../InternalHeader/Header'
import { useParams } from 'react-router-dom'
import {
  CURRENT_DASHBOARD_TAB,
  setCurrentDashboardTab,
} from '../../Store/dashboard/DashboardReducer'

const DashboardCmp = () => {
  const brain = useAppSelector(selectBrain)
  const { formId, reachId } = useParams()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (brain.id) dispatch(fetchFormsByBrain())
  }, [dispatch, brain.id])
  React.useEffect(() => {
    if (formId) {
      dispatch(setSelectedForm(formId))
      dispatch(getCurrentFormMemories(formId))
      dispatch(getCurrentFormReaches(formId))
      if (reachId) {
        dispatch(setCurrentDashboardTab(CURRENT_DASHBOARD_TAB.REACH))
      }
    }
  }, [formId, reachId, dispatch])

  if (brain.state === IAsyncState.LOADING) {
    return <LoadingBrain />
  }

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
        }}
      >
        <Sidebar />
        <Box sx={{ display: 'flex', width: '100%', flex: 1, padding: '20px' }}>
          <FormContentView />
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardCmp
