import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import { selectBrainForms, setSelectedForm } from '../../Store/brain/brainReducer'
import { useNavigate } from 'react-router-dom'

const FormsView = () => {
  const forms = useAppSelector(selectBrainForms)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <>
      {forms.all.map((f) => {
        return (
          <Box
            key={f.id}
            sx={{
              cursor: 'pointer',
              padding: '5px',
              paddingLeft: '30px',
              borderRadius: '5px',
              background: forms.selectedForm === f.id ? '#e2e2e2' : '',
              '&:hover': {
                background: 'rgb(173, 216, 230, 0.3)',
              },
            }}
            onClick={() => {
              navigate(`/dashboard/campaign/${f.id}`)
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: forms.selectedForm === f.id ? 'bold' : '',
                fontStyle: f.changed && 'italic',
              }}
            >
              {f.changed && '* '}
              {f.formName}
            </Typography>
          </Box>
        )
      })}
    </>
  )
}

export default FormsView
