import React from 'react'
import useMixpanel from '../../Hooks/useMixpanel'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useAppSelector } from '../../Hooks/StoreHooks'
import { selectBrain } from '../../Store/brain/brainReducer'

const GetResultsToEmail = () => {
  const [userData, setUserData] = React.useState({ email: '' })
  const [registered, setRegistered] = React.useState<boolean>(false)
  const brain = useAppSelector(selectBrain)
  const mixpanel = useMixpanel()

  const register = () => {
    setRegistered(true)
    mixpanel.track('user_registered', {
      email: userData.email,
      brainId: brain.id,
      brain,
      brainFileId: brain.fileId,
      from: 'digestion',
    })
  }
  if (registered)
    return (
      <Box
        sx={{
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #ADD8E6',
          borderRadius: '10px',
          padding: '20px',
          flexDirection: 'column',
          background: 'rgba(173, 216, 230, 0.1)',
          maxWidth: '300px',
        }}
      >
        <Typography variant="caption" sx={{ width: '85%' }}>
          We've got your details! If you want, you can keep this window open and wait for the
          summary of our conversation to load.
        </Typography>
      </Box>
    )
  return (
    <Box
      sx={{
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #ADD8E6',
        borderRadius: '10px',
        padding: '20px',
        flexDirection: 'column',
        background: 'rgba(173, 216, 230, 0.1)',
        maxWidth: '300px',
      }}
    >
      <Typography variant="caption" sx={{ width: '85%' }}>
        Your data will take a few minutes to be digested. While you are waiting - If you want to
        join our early access program add your email.
      </Typography>
      <TextField
        onChange={(e) => setUserData((data: any) => ({ ...data, email: e.target.value }))}
        value={userData?.email}
        sx={{ marginTop: '20px', width: '85%' }}
        type="outlined"
        placeholder="email"
      />
      <Button
        variant="contained"
        sx={{ marginTop: '20px', width: '85%' }}
        onClick={() => register()}
      >
        Notify Me
      </Button>
    </Box>
  )
}

export default GetResultsToEmail
