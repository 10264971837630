import { Box, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../Hooks/StoreHooks'
import { selectBrain } from '../../Store/brain/brainReducer'
import { drawerWidth } from '../InternalHeader/Constants'

const ContactsSidebar = () => {
  const brain = useAppSelector(selectBrain)
  return (
    <Box
      sx={{
        borderRight: '1px solid #e6e6e6',
        background: '#ffffff',
        width: `${drawerWidth}px`,
        padding: '20px',
      }}
    >
      <Box>
        <Typography variant="body2" sx={{ fontWeight: 'bold', padding: '10px' }}>
          Contacts preferences
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '10px',
          padding: '2px',
          cursor: 'pointer',
          '&:hover': {
            background: 'rgb(173, 216, 230, 0.3)',
          },
        }}
      >
        <Box
          sx={{ padding: '10px' }}
          onClick={() => {
            chrome.runtime.sendMessage(
              'kclidlefnmnjkjdodidlffamccnnegim',
              {
                action: 'startScraping',
              },
              (res: any) => {
                axios
                  .post(
                    `https://us-central1-thebrain-2d7e3.cloudfunctions.net/api/brain/${brain.id}/addlinkedincontacts`,
                    {
                      contacts: res,
                    }
                  )
                  .then((res) => {
                    // handle adding
                  })
              }
            )
          }}
        >
          <Typography variant="body2">Import Linkedin Contacts</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ContactsSidebar
