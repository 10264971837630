import { Box, Typography } from '@mui/material'
import React from 'react'
import brainanime from '../../Assets/brainanime2.gif'
const BrainLoader: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={brainanime} />
      <Typography variant="caption"> {text || 'Loading'}</Typography>
    </Box>
  )
}

export default BrainLoader
