import { v4 } from 'uuid'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export type Notification = {
  type?: 'info' | 'error'
  message: string
  duration?: number
  id: string
}

const initialState: Notification[] = []

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Omit<Notification, 'id'>>) => {
      const id = v4()
      state.push({ ...action.payload, id })
    },
    removeNotificationById: (state, action: PayloadAction<string>) => {
      return state.filter((n) => n.id !== action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { addNotification, removeNotificationById } = notificationsSlice.actions
export const selectNotifications = (state: RootState) => state.notifications
export default notificationsSlice.reducer
