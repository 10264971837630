import { Box, Button } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useAppSelector } from '../../Hooks/StoreHooks'
import { selectRecorder, IRecorderState } from '../../Store/recorder/recorder'

const RecorderCounter = () => {
  const recorder = useAppSelector(selectRecorder)
  if (recorder.status === IRecorderState.Stopped) return <></>
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red' }}>
      <FiberManualRecordIcon sx={{ marginRight: '10px' }} />
      {secondsToTime(120 - parseInt(recorder.time.toString()))}
    </Box>
  )
}

function secondsToTime(seconds: number) {
  let minutes = Math.floor(seconds / 60)
  let remainingSeconds = seconds % 60
  let time = `${minutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`
  return time
}

export default RecorderCounter
