import React from 'react'
import { useAppSelector } from '../../../Hooks/StoreHooks'
import { selectNotifications } from '../../../Store/notifications/notificationReducer'
import Notification from './Notification'

const NotificationContainer = () => {
  const notifications = useAppSelector(selectNotifications)

  return (
    <>
      {notifications.map((n) => {
        return (
          <Notification
            duration={n.duration}
            type={n.type}
            id={n.id}
            key={n.id}
            message={n.message}
          />
        )
      })}
    </>
  )
}

export default NotificationContainer
