import React from 'react'

import { Box, Typography, CircularProgress } from '@mui/material'
import { drawerWidth } from '../InternalHeader/Constants'
import AddBoxIcon from '@mui/icons-material/AddBox'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  createNewForm,
  fetchFormsByBrain,
  isBrainFormsLoading,
  selectBrain,
} from '../../Store/brain/brainReducer'
import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'

import FormsView from '../Dashboard.tsx/FormsView'
import { useLocation, useNavigate } from 'react-router-dom'

const Sidebar = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const brain = useAppSelector(selectBrain)
  const location = useLocation()

  const isLoadingForms = useAppSelector(isBrainFormsLoading)

  React.useEffect(() => {
    if (brain.id) dispatch(fetchFormsByBrain())
  }, [dispatch, brain.id])

  return (
    <Box
      sx={{
        borderRight: '1px solid #e6e6e6',
        background: '#ffffff',
        width: `${drawerWidth}px`,
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '10px',
          padding: '2px',
          cursor: 'pointer',
          '&:hover': {
            background: 'rgb(173, 216, 230, 0.3)',
          },
        }}
      >
        <AddBoxIcon sx={{ color: '#65acef' }} />
        <Box
          sx={{ padding: '10px' }}
          onClick={() => {
            dispatch(createNewForm())
          }}
        >
          <Typography variant="body2">New Campaign</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifuContent: 'center',
          marginTop: '30px',
        }}
      >
        <KeyboardArrowDownIcon />
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Your Campaigns
        </Typography>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {isLoadingForms && <CircularProgress size="1.2rem" />}
        </Box>
      </Box>
      <FormsView />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifuContent: 'center',
          marginTop: '30px',
          cursor: 'pointer',
          padding: '5px',
          borderRadius: '5px',
          background: location.pathname === '/user/settings' ? '#e2e2e2' : '',
          '&:hover': {
            background: 'rgb(173, 216, 230, 0.3)',
          },
        }}
        onClick={() => {
          navigate('/user/settings')
        }}
      >
        <BusinessCenterIcon sx={{ paddingRight: '5px', fontSize: '18px' }} />
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          SDR settings
        </Typography>
      </Box>
    </Box>
  )
}

export default Sidebar
