import React from 'react'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import RecorderCounter from '../Recorder/RecorderCounter'
import { useAppDispatch, useAppSelector } from '../../Hooks/StoreHooks'
import { startRecording, stopRecording, updateRecorderTime } from '../../Store/recorder/recorder'
import { UserMediaContext } from '../../Contexts/UserMediaContext'
import animatedLogo from '../../Assets/brainanime2.gif'

import {
  getQuestionsCount,
  moveNext,
  selectCurrentQuestionIndex,
  selectCurrentQuestion,
  selectForm,
  registerFormStartedRecording,
} from '../../Store/form/formReducer'
import { useNavigate } from 'react-router-dom'
import useMixpanel from '../../Hooks/useMixpanel'

const totalRecordingTime = 60

export const FormView: React.FC<any> = ({ gotoNextStep }) => {
  const umCtx = React.useContext(UserMediaContext)
  const boxRef = React.useRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const questionsCount = useAppSelector(getQuestionsCount)
  const currentQuestion = useAppSelector(selectCurrentQuestion)
  const currentQuestionIndex = useAppSelector(selectCurrentQuestionIndex)
  const mixpanel = useMixpanel()
  const navigate = useNavigate()

  const isUndefined = currentQuestion === undefined
  React.useEffect(() => {
    if (!isUndefined) {
      dispatch(startRecording())
      dispatch(registerFormStartedRecording())
    }
  }, [dispatch, isUndefined])

  const moveNextRef: any = () => {
    mixpanel.track('next_question_clicked', {
      page: 'FormView',
      currentQuestion,
      questionsCount,
      currentQuestionIndex,
    })
    if (currentQuestionIndex + 1 === questionsCount) {
      umCtx.stop()
      dispatch(stopRecording())
      navigate('/answer/upload')
      mixpanel.track('finished_questions', {
        page: 'FormView',
        currentQuestion,
        questionsCount,
        currentQuestionIndex,
      })
    } else {
      dispatch(moveNext())
      dispatch(updateRecorderTime(0))
    }
  }

  if (!currentQuestion)
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={animatedLogo} />
        <Typography variant="caption" sx={{ marginTop: '10px' }}>
          Ops.. Invalid link
        </Typography>
        <Typography variant="h6" sx={{}}>
          Please revisit the link that was shared with you
        </Typography>
      </Box>
    )

  return (
    <Box
      ref={boxRef}
      sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Box>
        <LinearProgress
          variant="determinate"
          value={((currentQuestionIndex + 1) / questionsCount) * 100}
          sx={{ height: '30px' }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <Typography variant="h3">{currentQuestion.text}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          position: 'relative',
          margin: '20px',
        }}
      >
        <RecorderCounter />
        <Box sx={{ flex: 1 }}>
          <WaveForm />
        </Box>
        <GoNextButton onClick={moveNextRef} />
      </Box>
    </Box>
  )
}

const GoNextButton = ({ onClick }: any) => {
  const questionsCount = useAppSelector(getQuestionsCount)
  const currentQuestionIndex = useAppSelector(selectCurrentQuestionIndex)

  const text = React.useMemo(() => {
    if (currentQuestionIndex + 1 === questionsCount) {
      return 'Finish'
    }
    return 'Next Question'
  }, [currentQuestionIndex, questionsCount])

  return (
    <Button variant="contained" sx={{ minWidth: '20%' }} onClick={onClick}>
      {text}
    </Button>
  )
}

const WaveForm = () => {
  const umCtx = React.useContext(UserMediaContext)
  const [audioData, setAudioData] = React.useState<Uint8Array>(new Uint8Array())
  React.useEffect(() => {
    const audioCtx = new (window.AudioContext || (window as any).webkitAudioContext)()
    const analyser = audioCtx.createAnalyser()
    const source = audioCtx.createMediaStreamSource((umCtx.stream as any).stream)
    source.connect(analyser)

    const interval = setInterval(() => {
      let dataArray = new Uint8Array(analyser.frequencyBinCount)
      analyser.getByteFrequencyData(dataArray)
      // console.log(dataArray.reduce((p, c) => p + c, 0))
      setAudioData(dataArray)
    }, 100)
    return () => {
      analyser.disconnect()
      source.disconnect()
      audioCtx.close()
      clearInterval(interval)
    }
  }, [setAudioData])

  return <AudioVisualiser audioData={audioData} />
}

const AudioVisualiser: React.FC<any> = ({ audioData }) => {
  const canvasRef = React.useRef(null)
  React.useEffect(() => {
    const canvas = canvasRef.current as any
    canvas.style.width = '100%'
    canvas.style.height = '100%'
    canvas.style.margin = '2px'
    // ...then set the internal size to match
    canvas.width = canvas.offsetWidth
    canvas.height = canvas.offsetHeight
  }, [canvasRef])
  const draw = React.useCallback(
    (ad: any) => {
      const audioData = ad.slice(1, ad.length / 4)
      const canvas = canvasRef.current as any
      const height = canvas.height
      const width = canvas.width
      const context = canvas.getContext('2d')
      let x = 0
      // const sliceWidth = (width * 1.0) / audioData.length
      const sliceWidth = (width / audioData.length) * 1

      context.lineWidth = 2
      context.strokeStyle = 'green'
      context.clearRect(0, 0, width, height)
      context.fillStyle = 'rgba(77, 171, 245, 0.8)'
      context.beginPath()
      // context.moveTo(0, height / 2)
      let idx = audioData.length
      for (const item of audioData) {
        idx = idx - 1
        const y = (item / 128.0) * height
        const barHeight = item / 2
        // context.lineTo(x, y)
        context.fillRect(x, (height - barHeight / 2) / 2, sliceWidth, y)

        // x += sliceWidth
        x += sliceWidth + 1.5
      }
      // context.lineTo(x, height / 2)
      context.stroke()
    },
    [canvasRef]
  )

  React.useEffect(() => {
    draw(audioData)
  }, [audioData, draw])

  return <canvas width="100%" height="80" ref={canvasRef} />
}

export default AudioVisualiser
