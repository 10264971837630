import Digest from '../Components/Form/Digest'
import Generate from '../Components/Form/Generate'
import NewBrain from '../Components/Form/NewBrain'
import UploadResult from '../Components/Form/UploadResult'
import Answer from '../Pages/Answer'
import CustomGenerate from '../Pages/CustomGenerate'
import Init from '../Pages/Init'
import Instructions from '../Pages/Instructions'
import Main from '../Pages/Main'
import Page404 from '../Pages/Page404'
import SignIn from '../Pages/SignIn'
import Dashboard from '../Pages/Dashboard'
import Mentor from '../Pages/Mentor'
import Session from '../Pages/Session'
import Contacts from '../Pages/Contacts'
import Reach from '../Pages/Reach'
import ConnectExtension from '../Pages/ConnectExtension'
import UserSettingsPage from '../Pages/UserSettings'

type NewType = {
  path: string
  page: () => JSX.Element
  isPrivate: boolean
  showHeader?: boolean
  fullWidth?: boolean
}

type Route = NewType

export const routes: Route[] = [
  {
    path: '/',
    page: Main,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/answer',
    page: Answer,
    isPrivate: false,
  },
  {
    path: '/answer/upload',
    page: UploadResult,
    isPrivate: false,
    showHeader: false,
  },
  {
    path: '/answer/newbrain',
    page: NewBrain,
    isPrivate: false,
    showHeader: false,
  },
  {
    path: '/answer/digest',
    page: Digest,
    isPrivate: false,
    showHeader: false,
  },
  {
    path: '/answer/generate',
    page: Generate,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/answer/generate/:brainId',
    page: CustomGenerate,
    isPrivate: false,
  },
  {
    path: '/instructions',
    page: Instructions,
    isPrivate: false,
  },
  {
    path: '/init',
    page: Init,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/signin',
    page: SignIn,
    isPrivate: false,
    showHeader: false,
  },
  {
    path: '/dashboard',
    page: Dashboard,
    isPrivate: true,
    showHeader: false,
    fullWidth: true,
  },
  {
    path: '/dashboard/campaign/:formId',
    page: Dashboard,
    isPrivate: true,
    showHeader: false,
    fullWidth: true,
  },
  {
    path: '/dashboard/campaign/:formId/reach/:reachId',
    page: Dashboard,
    isPrivate: true,
    showHeader: false,
    fullWidth: true,
  },
  {
    path: '/mentor/:id',
    page: Mentor,
    isPrivate: false,
    showHeader: false,
    fullWidth: false,
  },
  {
    path: '/reach/:id',
    page: Reach,
    isPrivate: false,
    showHeader: false,
    fullWidth: false,
  },
  {
    path: '/dashboard/session/:id',
    page: Session,
    isPrivate: true,
    showHeader: false,
    fullWidth: true,
  },
  {
    path: '/dashboard/contacts',
    page: Contacts,
    isPrivate: true,
    showHeader: false,
    fullWidth: true,
  },
  {
    path: '/user/settings',
    page: UserSettingsPage,
    isPrivate: true,
    showHeader: false,
    fullWidth: true,
  },
  {
    path: '/connect/ext',
    page: ConnectExtension,
    isPrivate: true,
    showHeader: false,
    fullWidth: true,
  },
  {
    path: '*',
    page: Page404,
    isPrivate: false,
  },
]
