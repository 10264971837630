import { Box, Typography } from '@mui/material'
import brainanime from '../../Assets/brainanime2.gif'

const LoadingBrain = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={brainanime} />
      <Typography variant="caption" sx={{ marginTop: '10px' }}>
        Please wait
      </Typography>
      <Typography variant="h6" sx={{}}>
        Loading brain
      </Typography>
    </Box>
  )
}

export default LoadingBrain
