import React, { useState } from 'react'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'

interface RangeSelectorProps {
  label: string
  min: number
  max: number
  onChange: (value: number[]) => void
  initialValue?: number[]
}

const RangeSelector: React.FC<RangeSelectorProps> = ({
  label,
  min,
  max,
  onChange,
  initialValue = [min, max],
}) => {
  const [value, setValue] = useState(initialValue)

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[])
    onChange(newValue as number[])
  }

  return (
    <div>
      <Typography id="range-slider" gutterBottom sx={{ fontWeight: 'bold' }}>
        {label} ({value})
      </Typography>
      <Slider
        min={min}
        max={max}
        step={1}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        sx={{
          color: 'primary.main',
          '& .MuiSlider-track': {
            borderRadius: 4,
          },
          '& .MuiSlider-thumb': {
            width: 24,
            height: 24,
            '&:hover, &:focus, &$active': {
              boxShadow: 'none',
            },
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'primary.main',
            fontWeight: 'bold',
            borderRadius: '50%',
            top: -28,
            '& span': {
              transform: 'rotate(-45deg)',
              '&::after': {
                content: '""',
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: '#fff',
              },
            },
          },
        }}
      />
    </div>
  )
}

export default RangeSelector
