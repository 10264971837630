import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export enum IRecorderState {
  Recording = 'recording',
  Stopped = 'stopped',
}

export type IRecorderRecording = {
  status: IRecorderState.Recording
  time: number
}

export type IRecorderStopped = {
  status: IRecorderState.Stopped
}

export type TRecorderState = IRecorderRecording | IRecorderStopped

const initialState: TRecorderState = {
  status: IRecorderState.Stopped,
}

export const recorderSlice = createSlice({
  name: 'recorder',
  initialState: initialState as TRecorderState,
  reducers: {
    updateRecorderTime: (state, action: PayloadAction<number>) => {
      if (state.status === IRecorderState.Recording) {
        state.time = action.payload
      }
    },
    startRecording: (state) => {
      if (state.status === IRecorderState.Stopped) {
        state = { ...state, status: IRecorderState.Recording, time: 0 }
        return state
      }
    },
    stopRecording: (state) => {
      state.status = IRecorderState.Stopped
    },
  },
})

// Action creators are generated for each case reducer function
export const { startRecording, stopRecording, updateRecorderTime } = recorderSlice.actions
export const selectRecorder = (state: RootState) => state.recorder
export default recorderSlice.reducer
