import { Box } from '@mui/system'
import React from 'react'
import { useParams } from 'react-router-dom'
import Generate from '../Components/Form/Generate'
import { useAppDispatch } from '../Hooks/StoreHooks'
import { setBrainId, setGoal } from '../Store/brain/brainReducer'

const GOALS = {
  problem:
    'Act as an enterpreneur. Explain in a numeric list: who is the ideal customer profile ? what is the problem we are solving ? what are the sub problems of this problem ?',
  elevator:
    'Act as an enterpreneur and write a transcript elevator pitch for the product. emphasize the strength of the product. It should be objective and not sound like a sales pitch.',
}
const CustomGenerate = () => {
  const { brainId } = useParams()
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    if (typeof brainId === 'string') {
      dispatch(setBrainId(brainId))
      dispatch(setGoal(GOALS.problem))
    }
  }, [dispatch, brainId])
  return (
    <Box sx={{ width: '100vw', minHeight: '100%' }}>
      Goal: <button onClick={() => dispatch(setGoal(GOALS.problem))}>Problem</button>
      Goal: <button onClick={() => dispatch(setGoal(GOALS.elevator))}>Elevator</button>
      <Generate />
    </Box>
  )
}

export default CustomGenerate
