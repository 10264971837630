import { Box } from '@mui/material'
import React from 'react'
import ContactsCmpt from '../Components/Contacts/ContactsCmp'
import ContactsSidebar from '../Components/Contacts/ContactsSidebar'
import Header from '../Components/InternalHeader/Header'

const Contacts = () => {
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
        }}
      >
        <ContactsSidebar />
        <Box sx={{ display: 'flex', width: '100%', flex: 1, padding: '20px' }}>
          <ContactsCmpt />
        </Box>
      </Box>
    </Box>
  )
}

export default Contacts
