import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export enum CURRENT_DASHBOARD_TAB {
  QUESTIONS = 'QUESTIONS',
  MEMORIES = 'MEMORIES',
  INSIGHTS = 'INSIGHTS',
  REACH = 'REACH',
  SETTINGS = 'SETTINGS',
}

type DashboardReducerState = {
  currentTab: CURRENT_DASHBOARD_TAB
}

const initialState = {
  currentTab: CURRENT_DASHBOARD_TAB.SETTINGS,
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState as DashboardReducerState,
  reducers: {
    setCurrentDashboardTab: (state, action: PayloadAction<CURRENT_DASHBOARD_TAB>) => {
      state.currentTab = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentDashboardTab } = dashboardSlice.actions
export const selectDashboard = (state: RootState) => state.dashboard
export default dashboardSlice.reducer
