import { configureStore } from '@reduxjs/toolkit'
import recordingWidgetReducer from './recordingWidget/recordingWidgetReducer'
import userReducer from './user/userReducer'
import recorderReducer from './recorder/recorder'
import formReducer from './form/formReducer'
import brainReducer from './brain/brainReducer'
import DashboardReducer from './dashboard/DashboardReducer'
import reachManagerReducer from './reachmanager/reachManagerReducer'
import notificationReducer from './notifications/notificationReducer'
// ...

export const store = configureStore({
  reducer: {
    user: userReducer,
    recordingWidget: recordingWidgetReducer,
    recorder: recorderReducer,
    form: formReducer,
    brain: brainReducer,
    dashboard: DashboardReducer,
    reachManager: reachManagerReducer,
    notifications: notificationReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
